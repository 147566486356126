'use client'

import { ReactNode } from 'react'

import { ItemPagePluginEntityDto } from 'types/dtos/item-page-plugins'
import {
  ItemPageAttributesDto,
  ItemPageDescriptionDto,
  ItemPageHorizontalScrollBlockDto,
  ItemPageHorizontalScrollBlockHeaderDto,
  ItemPageSellerBadgesInfoDto,
  ItemPageSellerFeedbacksDto,
  ItemPageSummaryDto,
} from 'types/dtos'
import ExposureBlock from 'components/ExposureBlock'
import clientSideMetrics from 'libs/common/client-side-metrics'

import ItemPageSellerReviewsPlugin, {
  ItemPageSellerFeedbacksPluginModel,
  getItemPageSellerFeedbacksPluginModel,
} from './SellerFeedbacks'
import {
  ItemPageAttributesBlockPlugin,
  ItemPageAttributesBlockPluginModel,
  getItemPageAttributesBlockPluginModel,
} from './AttributesBlock'
import { getItemPageSummaryPluginModel, ItemPageSummaryPluginModel } from './Summary'
import { PluginType } from '../types'
import { logItemPageError } from '../utils/observability'
import ItemPageSummaryPlugin from './Summary/ItemPageSummaryPlugin'
import ItemPageDescriptionPlugin from './Description/ItemPageDescriptionPlugin'
import { getItemPageDescriptionPluginModel } from './Description/transformers'
import { ItemPageDescriptionPluginModel } from './Description/types'
import ItemPageSellerBadgesInfoPlugin, {
  getItemPageSellerBadgesInfoPluginModel,
  ItemPageSellerBadgesInfoPluginModel,
} from './SellerBadgesInfo'
import {
  getItemPageHorizontalScrollBlockHeaderPluginModel,
  getItemPageHorizontalScrollBlockPluginModel,
  ItemPageHorizontalScrollBlockHeaderPluginModel,
  ItemPageHorizontalScrollBlockPluginModel,
  ItemPageHorizontalItemsBlockPlugin,
  ItemPageHorizontalItemsBlockHeaderPlugin,
} from './HorizontalScrollBlock'

const ITEM_PAGE_PLUGIN_ERROR_COUNT_METRIC_NAME = 'item_page_plugin_error_count'

type TransformerParams<TBackendData> = {
  data: TBackendData
  pluginName: string
}

type PluginConfig<TBackendData, TProps> = {
  component: (props: TProps & { pluginName: string }) => ReactNode
  transformer: (params: TransformerParams<TBackendData>) => TProps | null
}

type PluginConfigs = {
  [PluginType.Feedbacks]: PluginConfig<
    ItemPageSellerFeedbacksDto,
    ItemPageSellerFeedbacksPluginModel
  >
  [PluginType.Attributes]: PluginConfig<ItemPageAttributesDto, ItemPageAttributesBlockPluginModel>
  [PluginType.Summary]: PluginConfig<ItemPageSummaryDto, ItemPageSummaryPluginModel>
  [PluginType.Description]: PluginConfig<ItemPageDescriptionDto, ItemPageDescriptionPluginModel>
  [PluginType.SellerBadgesInfo]: PluginConfig<
    ItemPageSellerBadgesInfoDto,
    ItemPageSellerBadgesInfoPluginModel
  >
  [PluginType.HorizontalItemsBlockHeader]: PluginConfig<
    ItemPageHorizontalScrollBlockHeaderDto,
    ItemPageHorizontalScrollBlockHeaderPluginModel
  >
  [PluginType.HorizontalItemsBlock]: PluginConfig<
    ItemPageHorizontalScrollBlockDto,
    ItemPageHorizontalScrollBlockPluginModel
  >
}

const withCatch = <TData, TProps>(
  { data, pluginName }: TransformerParams<TData>,
  transformPluginData: (data: TData) => TProps,
): TProps | null => {
  try {
    return transformPluginData(data)
  } catch (error) {
    logItemPageError(error, 'be_plugin_transformation_failed', `plugin: ${pluginName}`)

    return null
  }
}

const pluginConfigs: PluginConfigs = {
  [PluginType.Feedbacks]: {
    component: ItemPageSellerReviewsPlugin,
    transformer: pluginProps => withCatch(pluginProps, getItemPageSellerFeedbacksPluginModel),
  },
  [PluginType.Attributes]: {
    component: ItemPageAttributesBlockPlugin,
    transformer: pluginProps => withCatch(pluginProps, getItemPageAttributesBlockPluginModel),
  },
  [PluginType.Summary]: {
    component: ItemPageSummaryPlugin,
    transformer: pluginProps => withCatch(pluginProps, getItemPageSummaryPluginModel),
  },
  [PluginType.Description]: {
    component: ItemPageDescriptionPlugin,
    transformer: pluginProps => withCatch(pluginProps, getItemPageDescriptionPluginModel),
  },
  [PluginType.SellerBadgesInfo]: {
    component: ItemPageSellerBadgesInfoPlugin,
    transformer: pluginProps => withCatch(pluginProps, getItemPageSellerBadgesInfoPluginModel),
  },
  [PluginType.HorizontalItemsBlock]: {
    component: ItemPageHorizontalItemsBlockPlugin,
    transformer: pluginProps => withCatch(pluginProps, getItemPageHorizontalScrollBlockPluginModel),
  },
  [PluginType.HorizontalItemsBlockHeader]: {
    component: ItemPageHorizontalItemsBlockHeaderPlugin,
    transformer: pluginProps =>
      withCatch(pluginProps, getItemPageHorizontalScrollBlockHeaderPluginModel),
  },
}

type Props = {
  data: ItemPagePluginEntityDto | undefined
}

// /**
//  * @example
//  * const ExampleSidebarComponent = (props) => {
//  *    const relevantPlugins = plugins.filter(p => p.section == 'sidebar')
//  *    return <>
//  *      {relevantPlugins.map(p => <Plugin {...p} />)}
//  *    </>
//  * }
//  */
const Plugin = ({ data }: Props) => {
  if (!data) return undefined

  const pluginConfig: any = pluginConfigs[data.type]
  if (!pluginConfig) return undefined

  const componentProps = pluginConfig.transformer({ data: data.data, pluginName: data.name })
  if (!componentProps) {
    clientSideMetrics
      .counter(ITEM_PAGE_PLUGIN_ERROR_COUNT_METRIC_NAME, { name: data.name })
      .increment()
  }

  const Component = pluginConfig.component

  return (
    <>
      {data.exposure && <ExposureBlock {...data.exposure} />}
      {componentProps && <Component {...componentProps} pluginName={data.name} />}
    </>
  )
}

export default Plugin
