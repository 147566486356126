'use client'

import { useMemo } from 'react'
import { EmptyState, Image, Loader } from '@vinted/web-ui'
import { InView } from 'react-intersection-observer'

import ItemViewItems from 'components/ItemViewItems'
import useAsset from 'hooks/useAsset'
import useFetch from 'hooks/useFetch'
import useTranslate from 'hooks/useTranslate'
import { getItemsByPluginName } from 'data/api'

import { ItemPageHorizontalScrollBlockPluginModel } from './types'
import { transformHorizontalScrollBlockItemDtoToProductItem } from './transformers'

const EMPTY_COLLECTION_IMAGE_PATH = 'hanger-empty-state-96.svg'

const ItemPageHorizontalItemsBlockPlugin = ({
  items,
  contentSource,
  itemId,
  pluginName,
}: ItemPageHorizontalScrollBlockPluginModel & { pluginName: string }) => {
  const asset = useAsset('/assets/no-photo')
  const translate = useTranslate('item.horizontal_items_block_plugin')
  const {
    fetch: fetchItemsByPluginNameData,
    data: loadedData,
    isLoading,
    error,
  } = useFetch(getItemsByPluginName)

  const data = useMemo(() => {
    if (loadedData) {
      return loadedData.items.map(transformHorizontalScrollBlockItemDtoToProductItem)
    }

    return items
  }, [loadedData, items])

  const handleInViewChange = (inView: boolean) => {
    if (!inView || isLoading || data !== null) return

    fetchItemsByPluginNameData({ itemId, name: pluginName })
  }

  const renderContent = () => {
    if (data === null || isLoading) {
      return (
        <div
          className="u-flexbox u-justify-content-center item-page-feed-loader"
          data-testid="item-feed-loader"
        >
          <Loader size={Loader.Size.Large} />
        </div>
      )
    }
    if (error || data.length === 0)
      return (
        <EmptyState
          title={translate('empty_state.title')}
          image={<Image src={asset(EMPTY_COLLECTION_IMAGE_PATH)} />}
          testId={`${pluginName}-plugin-empty-state`}
        />
      )

    return (
      <ItemViewItems
        items={data}
        contentSource={contentSource}
        itemTestId={pluginName}
        horizontalScrollEnabled
      />
    )
  }

  return (
    <InView
      as="div"
      data-testid={`item-page-${pluginName}-plugin`}
      onChange={handleInViewChange}
      rootMargin="0px 0px 60px 0px"
    >
      {renderContent()}
    </InView>
  )
}

export default ItemPageHorizontalItemsBlockPlugin
