'use client'

import useSession from 'hooks/useSession'

import ItemPerformanceEmptyState from 'components/ItemPerformance/ItemPerformanceEmptyState'
import ItemPerformance from 'components/ItemPerformance'

import { ItemPagePerformanceBlockModel } from './types'

type Props = {
  performanceBlockPluginModel: ItemPagePerformanceBlockModel | undefined
}

const ItemPagePerformanceBlockPlugin = ({ performanceBlockPluginModel }: Props) => {
  const { user: currentUser } = useSession()

  if (!performanceBlockPluginModel) return null

  const { performance, statsVisible, promoted, userId } = performanceBlockPluginModel
  const isViewingSelf = currentUser?.id === userId

  if (!isViewingSelf || !statsVisible) return null

  if (!performance) return <ItemPerformanceEmptyState />

  return <ItemPerformance performance={performance} itemPromoted={promoted} />
}

export default ItemPagePerformanceBlockPlugin
