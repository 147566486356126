'use client'

import { useContext } from 'react'

import ServiceFeeIncludedTitle from 'components/ServiceFeeIncludedTitle'

import { transformCurrencyAmountDto } from 'data/transformers/currency-amount'

import { CurrencyAmountModel } from 'types/models'
import { ItemPhotoDto } from 'types/dtos'

import useSession from 'hooks/useSession'

import { ItemThumbnailSize } from 'constants/images'

import { ShippingDetailsContext } from '../../containers/ShippingDetailsProvider'
import { EscrowFeesContext } from '../../containers/EscrowFeesProvider'
import { ItemPagePricingPluginModel } from './types'
import DiscountBadges from './DiscountBadges'
import ItemPriceHeading from './ItemPriceHeading'
import ItemCurrencyConversionNote from './ItemCurrencyConversionNote'

type Props = {
  data: ItemPagePricingPluginModel
}

const ItemPagePricingPlugin = ({ data }: Props) => {
  const { user } = useSession()
  const currentUserId = user?.id
  const isUserLoggedIn = !!user
  const { shippingDetails } = useContext(ShippingDetailsContext)
  const { escrowFees } = useContext(EscrowFeesContext)
  const {
    itemPrice,
    discountedPrice,
    offerPrice,
    totalItemPrice,
    serviceFee,
    badge,
    itemId,
    isSellerBusiness,
    itemUserId,
    itemTitle,
    photos,
    conversion,
  } = data

  const isViewingSelf = itemUserId === currentUserId

  const transformedItemPrice = transformCurrencyAmountDto(itemPrice)
  const discountPriceCurrencyAmount: CurrencyAmountModel | null = discountedPrice
    ? transformCurrencyAmountDto(discountedPrice)
    : null

  const transformedTotalPrice = totalItemPrice ? transformCurrencyAmountDto(totalItemPrice) : null
  const transformedServiceFee = serviceFee ? transformCurrencyAmountDto(serviceFee) : null

  const getPhotoThumbnail = (photo: ItemPhotoDto) => {
    const thumbnail = photo?.thumbnails?.find(thumb => thumb.type === ItemThumbnailSize.Medium)

    return thumbnail?.url
  }

  const itemPhotoSrc = photos && getPhotoThumbnail(photos[0]!)

  const shouldShowDetailedBreakdown = isUserLoggedIn && !!escrowFees
  const detailedBreakdownProps = shouldShowDetailedBreakdown
    ? {
        itemTitle,
        escrowFees,
        itemPhotoSrc,
      }
    : null

  const shouldShowDiscountBadges = isUserLoggedIn && !isViewingSelf && !!escrowFees

  return (
    <div className="details-list details-list--pricing">
      <div data-testid="item-sidebar-price-container">
        <ItemPriceHeading
          badge={badge}
          price={transformedItemPrice}
          discountedPrice={discountPriceCurrencyAmount}
          offerPrice={offerPrice}
          isItemOwner={isViewingSelf}
        />
        {!!shippingDetails?.price && (
          <ServiceFeeIncludedTitle
            itemId={itemId}
            itemPrice={offerPrice || discountPriceCurrencyAmount || transformedItemPrice}
            isItemOwner={isViewingSelf}
            totalItemPrice={transformedTotalPrice}
            serviceFee={transformedServiceFee}
            isSellerBusiness={isSellerBusiness}
            shouldTrimTitle={isViewingSelf}
            detailedBreakdown={detailedBreakdownProps}
            showInclinePrice={false}
          />
        )}
        {shouldShowDiscountBadges && (
          <DiscountBadges
            escrowFees={escrowFees}
            isSellerBusiness={isSellerBusiness}
            itemUserId={itemUserId}
            itemTitle={itemTitle}
            itemPhotoSrc={itemPhotoSrc}
            itemPrice={transformedItemPrice}
            itemId={itemId}
          />
        )}
      </div>
      <div
        className="details-list__item details-list--badges"
        data-testid="item-sidebar-pricing-details-container"
      >
        {conversion && <ItemCurrencyConversionNote />}
      </div>
    </div>
  )
}

export default ItemPagePricingPlugin
