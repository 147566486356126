
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/items/[itemId]",
      function () {
        return require("private-next-pages/items/[itemId]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/items/[itemId]"])
      });
    }
  