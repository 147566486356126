import { ItemDto } from 'types/dtos'

import { transformItemPerformance } from 'data/transformers/item-performance'

import { ItemPagePerformanceBlockModel } from '../types'

export const getItemPagePerformanceBlockPluginModel = (
  itemDto: ItemDto,
): ItemPagePerformanceBlockModel => ({
  performance:
    itemDto.performance && Object.keys(itemDto.performance).length
      ? transformItemPerformance(itemDto.performance)
      : null,
  statsVisible: itemDto.stats_visible,
  promoted: itemDto.promoted,
  userId: itemDto.user.id,
})
