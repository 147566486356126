'use client'

import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Cell, Text } from '@vinted/web-ui'

import { TransparencyLawSubject } from 'constants/index'
import * as selectors from 'state/system-configuration/selectors'
import { actions } from 'state/system-configuration/slice'
import useTranslate from 'hooks/useTranslate'

type Props = {
  subject: TransparencyLawSubject
}

const TransparencyLaw = ({ subject }: Props) => {
  const translate = useTranslate()
  const dispatch = useDispatch()

  const systemConfiguration = useSelector(selectors.getSystemConfiguration)

  const [isExpanded, setIsExpanded] = useState(false)
  const [text, secondText] = useMemo(() => {
    if (!systemConfiguration) return ['', '']

    const key = systemConfiguration.transparencyLawKeys[subject]

    if (!key) return ['', '']

    const translated = translate(key)
    const translatedText = translated ? translated.toString().split('[read_more]') : ['', '']

    // eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion
    return [`${translatedText[0]!?.trim()} `, translatedText[1]?.trim() || '']
  }, [translate, systemConfiguration, subject])

  useEffect(() => {
    if (systemConfiguration) return

    dispatch(actions.fetchSystemConfigurationRequest())
  }, [dispatch, systemConfiguration])

  const handleActionClick = () => {
    setIsExpanded(prevIsExpanded => !prevIsExpanded)
  }

  const renderAction = () => {
    if (isExpanded || !secondText) return null

    return (
      <button type="button" onClick={handleActionClick}>
        <Text
          testId="see-more-legal-notes"
          inline
          type={Text.Type.Caption}
          clickable
          text={translate('transparency_law.actions.read_more')}
          as="span"
        />
      </button>
    )
  }

  if (!text) return null

  return (
    <Cell theme="transparent" testId="item-transparency-law">
      <Text
        html
        inline
        type={Text.Type.Caption}
        as="p"
        text={isExpanded ? text + secondText : text}
      />
      {renderAction()}
    </Cell>
  )
}

export default TransparencyLaw
