'use client'

import { useContext } from 'react'
import { InView } from 'react-intersection-observer'

import useAbTest from 'hooks/useAbTest'
import useAbTestExposeTracking from 'hooks/useAbTestExposeTracking'
import { AbTestVariant } from 'constants/abtest'

import ItemPageClosetPlugin from '../plugins/Closet'
import { ItemPageClosetPluginDto } from '../plugins/Closet/types'
import ItemPageSimilarItemsPlugin from '../plugins/SimilarItems'
import { ItemPageSimilarItemsPluginModel } from '../plugins/SimilarItems/types'
import { ItemPageShopBundlesBlockPluginModel } from '../plugins/ShopBundlesBlock/types'
import ItemPageShopBundlesBlockPlugin from '../plugins/ShopBundlesBlock'
import ItemPageClosetTitlePlugin from '../plugins/ClosetTitle'
import { ItemPageClosetTitlePluginModel } from '../plugins/ClosetTitle/types'
import Plugin from '../plugins/Plugin'
import { PluginName, SectionType } from '../types'
import { PluginsContext } from '../containers/PluginsProvider'

type Props = {
  closetData: ItemPageClosetPluginDto | undefined
  similarItemsData: ItemPageSimilarItemsPluginModel | undefined
  shopBundlesBlockData: ItemPageShopBundlesBlockPluginModel | undefined
  closetTitleData: ItemPageClosetTitlePluginModel | undefined
}

const Feed = ({ closetData, similarItemsData, shopBundlesBlockData, closetTitleData }: Props) => {
  const { plugins: serverSidePlugins } = useContext(PluginsContext)
  const buyerHoldoutAbTestEnabled =
    useAbTest({ abTestName: 'buyer_domain_holdout_2024q4' })?.variant === AbTestVariant.On
  const horizontalBlocksTest = useAbTest({ abTestName: 'item_page_horizontal_item_blocks' })
  const { trackExpose: trackHorizontalBlocksExpose } = useAbTestExposeTracking({
    abTestName: 'item_page_horizontal_item_blocks',
  })

  const horizontalBlockAbTestEnabled =
    buyerHoldoutAbTestEnabled &&
    horizontalBlocksTest &&
    horizontalBlocksTest.variant !== AbTestVariant.Off

  const feedPlugins = serverSidePlugins?.filter(plugin => plugin.section === SectionType.Feed)

  const renderFeedPlugins = () => {
    if (!feedPlugins) return null

    return feedPlugins.flatMap(pluginProps => {
      const elements = [<Plugin key={pluginProps.name} data={pluginProps} />]

      if (pluginProps.name === PluginName.OtherUserItemsHeader && shopBundlesBlockData) {
        elements.push(
          <ItemPageShopBundlesBlockPlugin key="shop_bundles_block" data={shopBundlesBlockData} />,
        )
      }

      return elements
    })
  }

  const handleCrossSellingChannelsView = (inView: boolean) =>
    inView && buyerHoldoutAbTestEnabled && horizontalBlocksTest && trackHorizontalBlocksExpose()

  return (
    <div className="u-ui-margin-top-medium">
      <Plugin data={serverSidePlugins?.find(plugin => plugin.name === PluginName.Feedbacks)} />
      <InView as="div" onChange={handleCrossSellingChannelsView} triggerOnce>
        {horizontalBlockAbTestEnabled ? (
          renderFeedPlugins()
        ) : (
          <>
            <ItemPageClosetTitlePlugin data={closetTitleData} />
            {shopBundlesBlockData && <ItemPageShopBundlesBlockPlugin data={shopBundlesBlockData} />}
            {closetData && <ItemPageClosetPlugin data={closetData} />}
            <ItemPageSimilarItemsPlugin data={similarItemsData} />
          </>
        )}
      </InView>
    </div>
  )
}

export default Feed
