import { ItemPageSummaryDto, SummaryLineDto } from 'types/dtos'

import { ItemPageSummaryPluginModel, SummaryLineModel } from './types'

const transformSummaryLineDto = (summaryLine: SummaryLineDto): SummaryLineModel => ({
  maxLines: summaryLine.max_lines,
  elements: summaryLine.elements,
})

export const getItemPageSummaryPluginModel = (
  summaryDto: ItemPageSummaryDto,
): ItemPageSummaryPluginModel => {
  return {
    itemId: summaryDto.item_id,
    lines: summaryDto.lines.map(transformSummaryLineDto),
  }
}
