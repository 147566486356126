import { ReactNode } from 'react'
import { Cell, Text } from '@vinted/web-ui'

type DropdownMenuDescriptionProps = {
  text: ReactNode
}

const DropdownMenuDescription = ({ text }: DropdownMenuDescriptionProps) => {
  return <Cell body={<Text type={Text.Type.Subtitle} as="p" text={text} />} />
}

export default DropdownMenuDescription
