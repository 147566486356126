'use client'

import { useSelector } from 'react-redux'
import { Cell, Image, Spacer, Text } from '@vinted/web-ui'

import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'
import useAbTest from 'hooks/useAbTest'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

type Props = {
  isTight?: boolean
}

const UserBadge = ({ isTight }: Props) => {
  const translate = useTranslate('user.seller_badges.active_lister')
  const asset = useAsset('/assets/user-badges')

  const isUserBadgeEnabled = useSelector(
    getIsFeatureSwitchEnabled('active_lister_badge_buyer_side_web'),
  )

  const activeListerBadgeBuyerSideAbTest = useAbTest({
    abTestName: 'active_lister_badge_buyer_side',
  })

  const showBadge =
    isUserBadgeEnabled &&
    activeListerBadgeBuyerSideAbTest &&
    activeListerBadgeBuyerSideAbTest.variant === 'on'

  if (!showBadge) return null

  return (
    <div className="u-flexbox u-background-white">
      {isTight && <Spacer size={Spacer.Size.Large} orientation={Spacer.Orientation.Vertical} />}
      <Cell
        styling={isTight ? Cell.Styling.Tight : Cell.Styling.Default}
        prefix={
          <Image
            src={asset('lister-badge.svg')}
            alt=""
            size={Image.Size.Medium}
            scaling={Image.Scaling.Contain}
          />
        }
        title={translate('title')}
        body={<Text as="h3" text={translate('body')} type={Text.Type.Subtitle} />}
      />
    </div>
  )
}

export default UserBadge
