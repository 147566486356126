import { Cell } from '@vinted/web-ui'

import ItemPageGalleryPlugin from '../plugins/Gallery'
import { ItemPageGalleryPluginModel } from '../plugins/Gallery/types'
import ItemPageBreadcrumbsPlugin from '../plugins/Breadcrumbs/ItemPageBreadcrumbsPlugin'
import ItemPageAlertsPlugin from '../plugins/Alerts/ItemPageAlertsPlugin'
import { ItemPageBreadcrumbsPluginModel } from '../plugins/Breadcrumbs/types'
import { ItemPageAlertsPluginModel } from '../plugins/Alerts/types'
import ItemPageReportButtonPlugin from '../plugins/ReportButton'
import { ItemPageReportButtonPluginModel } from '../plugins/ReportButton/types'

type Props = {
  isUserAdmin: boolean
  galleryPluginModel: ItemPageGalleryPluginModel | undefined
  breadcrumbsPluginModel: ItemPageBreadcrumbsPluginModel | undefined
  alertPluginModel: ItemPageAlertsPluginModel | undefined
  reportButtonPluginModel: ItemPageReportButtonPluginModel | undefined
}

const ContentSection = ({
  isUserAdmin,
  galleryPluginModel,
  breadcrumbsPluginModel,
  alertPluginModel,
  reportButtonPluginModel,
}: Props) => (
  <>
    {alertPluginModel && <ItemPageAlertsPlugin data={alertPluginModel} isUserAdmin={isUserAdmin} />}
    {galleryPluginModel && <ItemPageGalleryPlugin data={galleryPluginModel} />}
    <Cell
      theme="transparent"
      styling={Cell.Styling.Tight}
      body={<ItemPageBreadcrumbsPlugin data={breadcrumbsPluginModel} />}
      suffix={<ItemPageReportButtonPlugin data={reportButtonPluginModel} />}
    />
  </>
)

export default ContentSection
