type AttributeDataBaseDto = {
  title: string
  value: string
}

export enum AttributeType {
  Text = 'text',
  Faq = 'faq',
  Navigational = 'navigational',
  Modal = 'modal',
  Favouritable = 'favouritable',
}

export type AttributeTextDto = {
  type: AttributeType.Text
  code: string
  data: AttributeDataBaseDto
}

export type AttributeFaqDto = {
  type: AttributeType.Faq
  code: string
  data: AttributeDataBaseDto & {
    id: string
  }
}

export type AttributeNavigationalDto = {
  type: AttributeType.Navigational
  code: string
  data: AttributeDataBaseDto & {
    id: string
    url: string
  }
}

export type AttributeModalDto = {
  type: AttributeType.Modal
  code: string
  data: AttributeDataBaseDto
}

export type AttributeFavouritableDto = {
  type: AttributeType.Favouritable
  code: string
  data: AttributeDataBaseDto & {
    id: string
    url: string
    is_favourite: boolean
  }
}

export type ItemPageAttributeDto =
  | AttributeTextDto
  | AttributeFaqDto
  | AttributeNavigationalDto
  | AttributeModalDto
  | AttributeFavouritableDto

export type ItemPageAttributesDto = {
  attributes: Array<ItemPageAttributeDto>
  item_id: number
}
