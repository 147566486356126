'use client'

import { useMemo, ReactNode } from 'react'

import { ItemShippingDetailsModel } from 'types/models/shipping-option'

import ShippingDetailsContext from './ShippingDetailsContext'

type Props = {
  children: ReactNode
  shippingDetails?: ItemShippingDetailsModel | null
}

const ShippingDetailsProvider = ({ children, shippingDetails }: Props) => {
  const shippingDetailsMemo = useMemo(
    () => ({
      shippingDetails,
    }),
    [shippingDetails],
  )

  return (
    <ShippingDetailsContext.Provider value={shippingDetailsMemo}>
      {children}
    </ShippingDetailsContext.Provider>
  )
}

export default ShippingDetailsProvider
