import { ItemDto } from 'types/dtos'

export const getItemPageClosetPluginDto = (itemDto: ItemDto) => ({
  itemId: itemDto.id,
  canBuyItem: itemDto.can_buy,
  canBundle: itemDto.can_bundle,
  closetCountdownEndDate: itemDto.user.closet_countdown_end_date || '',
  itemUserId: itemDto.user.id,
  bundleDiscount: itemDto.user.bundle_discount,
  isUserHated: itemDto.user.is_hated,
  isUserHatesYou: itemDto.user.hates_you,
})
