'use client'

import { Label, Text } from '@vinted/web-ui'
import { useInView } from 'react-intersection-observer'

import useTracking from 'hooks/useTracking'
import {
  itemPageChannelClickEvent,
  itemPageChannelViewEvent,
} from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import { ItemPageHorizontalScrollBlockHeaderPluginModel } from './types'

const ItemPageHorizontalItemsBlockHeaderPlugin = ({
  title,
  headerCta,
  pluginName,
  itemId,
  contentSource,
}: ItemPageHorizontalScrollBlockHeaderPluginModel & { pluginName: string }) => {
  const { track } = useTracking()
  const { ref } = useInView({
    onChange: inView => inView && track(itemPageChannelViewEvent({ itemId, contentSource })),
    triggerOnce: true,
  })

  const handleCtaClick = () => {
    track(
      itemPageChannelClickEvent({
        itemId,
        contentSource,
        target: ClickableElement.HorizontalItemsBlockHeaderCta,
      }),
    )
  }

  return (
    <div ref={ref}>
      <Label
        text={
          <Text type={Text.Type.Subtitle} as="h2">
            {title}
          </Text>
        }
        suffix={
          headerCta && (
            <a
              href={headerCta.url}
              onClick={handleCtaClick}
              target="_blank"
              rel="noreferrer"
              data-testid="horizontal-items-block-header-suffix"
            >
              <Text type={Text.Type.Subtitle} theme="primary" text={headerCta.title} as="span" />
            </a>
          )
        }
        testId={`item-page-${pluginName}-plugin`}
      />
    </div>
  )
}

export default ItemPageHorizontalItemsBlockHeaderPlugin
