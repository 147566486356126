'use client'

import { useEffect, useContext } from 'react'
import { Cell, Divider, Spacer, Text } from '@vinted/web-ui'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import useShippingPrice from 'hooks/useShippingPrice'

import { Screen } from 'constants/tracking/screens'
import { viewShippingPriceEvent } from 'libs/common/event-tracker/events'

import { ShippingDetailsContext } from '../../containers/ShippingDetailsProvider'
import ShippingDiscountBanner from './ShippingDiscountBanner'
import { ItemPageShippingPricePluginModel } from './types'

type Props = {
  data: ItemPageShippingPricePluginModel
}

const ItemPageShippingPricePlugin = ({ data: { itemId } }: Props) => {
  const translate = useTranslate('item.shipment_options')
  const { track } = useTracking()
  const { shippingDetails } = useContext(ShippingDetailsContext)

  const shippingPrice = useShippingPrice({
    shippingDetails: shippingDetails || undefined,
    trimTranslation: true,
  })

  useEffect(() => {
    if (!shippingDetails) return

    const trackingEvent = viewShippingPriceEvent({
      screen: Screen.Item,
      prices: [shippingDetails.price.amount],
      itemId,
    })

    track(trackingEvent)
  }, [track, itemId, shippingDetails])

  if (!shippingDetails) return null

  const title = shippingDetails.isFreeShipping ? translate('free_shipping') : translate('shipping')
  const shippingPriceTitle = shippingDetails.isFreeShipping ? '' : shippingPrice

  return (
    <>
      <Divider />
      <Spacer />
      <Cell
        styling={Cell.Styling.Tight}
        title={
          <Text
            as="h3"
            text={title}
            type={Text.Type.Subtitle}
            theme="amplified"
            testId="item-shipping-banner-title"
          />
        }
        suffix={
          <Text
            as="h3"
            text={shippingPriceTitle}
            type={Text.Type.Subtitle}
            testId="item-shipping-banner-price"
          />
        }
        testId="item-shipping-banner"
      />
      {shippingDetails.discount && (
        <>
          <Spacer />
          <ShippingDiscountBanner
            deliveryType={shippingDetails.discount.deliveryType}
            discountPercentage={shippingDetails.discount.maxPercent}
          />
        </>
      )}
    </>
  )
}

export default ItemPageShippingPricePlugin
