import { ReactNode, useContext } from 'react'

import { ToggleableContext } from 'components/Toggleable/ToggleableContext'

type Props = {
  children: (props: { toggle: () => void; isDropdownVisible: boolean }) => ReactNode
}

const DropdownToggle = ({ children }: Props) => {
  const { toggle, isVisible } = useContext(ToggleableContext)

  return children({ toggle, isDropdownVisible: isVisible })
}

export default DropdownToggle
