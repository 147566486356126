import { ItemDto } from 'types/dtos'

import { ItemPageDeleteActionModel } from '../types'

export const getItemPageDeleteActionPluginModel = (
  itemDto: ItemDto,
): ItemPageDeleteActionModel => ({
  id: itemDto.id,
  userId: itemDto.user.id,
  canDelete: itemDto.can_delete,
  isClosed: itemDto.is_closed,
})
