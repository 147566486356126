'use client'

import { useState, useEffect, useCallback, Fragment, useRef } from 'react'
import { createPortal } from 'react-dom'
import { Icon, Loader } from '@vinted/web-ui'
import { noop } from 'lodash'
import classNames from 'classnames'
import { ChevronLeft16, ChevronRight16, X16 } from '@vinted/monochrome-icons'

import OutsideClick from 'components/OutsideClick'
import { KeyboardKey } from 'constants/keyboard'
import useTracking from 'hooks/useTracking/useTracking'
import { userViewItemGallery } from 'libs/common/event-tracker/events'

type Props = {
  imageSources: Array<string>
  initialImageIndex: number
  onClose: () => void
  itemId?: number
}

const ImageCarousel = ({ imageSources, initialImageIndex, onClose = noop, itemId }: Props) => {
  const { track } = useTracking()
  const [currentImageIndex, setCurrentImageIndex] = useState(initialImageIndex)
  const [loadedImageIndexes, setLoadedImageIndexes] = useState<Array<number>>([])
  const hasCarouselLooped = useRef<boolean>(false)

  const showPreviousImage = useCallback(() => {
    setCurrentImageIndex(index => {
      if (index > 0) return index - 1

      hasCarouselLooped.current = true

      return imageSources.length - 1
    })
  }, [imageSources.length])

  const showNextImage = useCallback(() => {
    setCurrentImageIndex(index => {
      if (index + 1 === imageSources.length) hasCarouselLooped.current = true

      return (index + 1) % imageSources.length
    })
  }, [imageSources.length])

  const trackUserViewItemGallery = useCallback(
    ({ imagePosition, imageCount }) => {
      if (!itemId) return

      track(
        userViewItemGallery({
          itemId,
          imagePosition,
          isLoop: hasCarouselLooped.current,
          imageCount,
          contentType: 'gallery_image',
          galleryContainsAd: false,
        }),
      )

      hasCarouselLooped.current = false
    },
    [itemId, track],
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.code) {
        case KeyboardKey.ArrowLeft:
        case KeyboardKey.Backspace:
          showPreviousImage()
          break
        case KeyboardKey.ArrowRight:
        case KeyboardKey.Enter:
          showNextImage()
          break
        case KeyboardKey.Escape:
          onClose()
          break
        case KeyboardKey.Tab:
          event.preventDefault()
          break
        default:
          break
      }
    },
    [onClose, showPreviousImage, showNextImage],
  )

  useEffect(() => {
    trackUserViewItemGallery({
      imagePosition: currentImageIndex + 1,
      imageCount: imageSources.length,
    })
  }, [currentImageIndex, imageSources, trackUserViewItemGallery])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  const handleImageLoad = (imageIndex: number) => () => {
    setLoadedImageIndexes(indeces => [...indeces, imageIndex])
  }

  function renderImages() {
    return imageSources.map((src, index) => {
      const isLoaded = loadedImageIndexes.includes(index)
      const isShown = index === currentImageIndex
      const showLoader = isShown && !isLoaded

      return (
        <Fragment key={index}>
          {showLoader && <Loader />}
          <img
            src={src}
            alt="post"
            data-testid={`image-carousel-image${isShown ? '-shown' : ''}`}
            className={classNames(
              'image-carousel__image',
              isShown ? 'image-carousel__image--shown' : null,
            )}
            onLoad={handleImageLoad(index)}
          />
        </Fragment>
      )
    })
  }

  function renderCarouselIcon(name: ComponentProps<typeof Icon>['name']) {
    return (
      <div className="image-carousel__icon-wrapper">
        <Icon name={name} color={Icon.Color.GreyscaleLevel7} />
      </div>
    )
  }

  if (!imageSources.length) return null

  const showControls = imageSources.length > 1

  return (
    <div className="image-carousel" data-testid="image-carousel">
      <OutsideClick onOutsideClick={onClose}>
        <div className="image-carousel__image-wrapper">
          {showControls && (
            <>
              <button
                type="button"
                className="image-carousel__button"
                data-testid="image-carousel-button-left"
                onClick={showPreviousImage}
              >
                {renderCarouselIcon(ChevronLeft16)}
              </button>
              <button
                type="button"
                className="image-carousel__button image-carousel__button--right"
                data-testid="image-carousel-button-right"
                onClick={showNextImage}
              >
                {renderCarouselIcon(ChevronRight16)}
              </button>
            </>
          )}
          <button
            type="button"
            className="image-carousel__button image-carousel__button--close"
            data-testid="image-carousel-button-close"
            onClick={onClose}
          >
            {renderCarouselIcon(X16)}
          </button>
          {renderImages()}
        </div>
      </OutsideClick>
    </div>
  )
}

export default (props: Props) => createPortal(<ImageCarousel {...props} />, document.body)
