import { kebabCase } from 'lodash'

import { AccessChannel } from 'constants/index'
import { HELP_CENTER_URL } from 'constants/routes'
import { HC_ROOT_FAQ_ID } from 'constants/help-center'
import { HC_FAQ_PATH, HC_ROOT_PATH } from 'constants/routes/help-center'

import { urlWithParams } from './url'

const FAQ_ENTRY_PATH_REGEX = /^\/([^/]+)\/?(\d+)?\/?(\d+)?/
const URL_REGEX = /https?:\/\/[^\s/$.?#].[^\s"'>]*/g

type FaqEntryUrlProps = {
  id: number
  title?: string | null
  faqEntryParentId?: number
}

export const getFaqEntryPath = ({ id, title, faqEntryParentId }: FaqEntryUrlProps) => {
  let faqEntryPath = ''

  if (faqEntryParentId) {
    faqEntryPath = `${faqEntryParentId}/`
  }

  faqEntryPath += title ? kebabCase(`${id}-${title}`) : id.toString()

  return faqEntryPath
}

export const generateFaqLink = (
  faqEntry: FaqEntryUrlProps,
  accessChannel: Array<string> | string | undefined,
  threadId: Array<string> | string | undefined,
) => {
  if (faqEntry.id === HC_ROOT_FAQ_ID) {
    return urlWithParams(HC_ROOT_PATH, {
      thread_id: threadId,
    })
  }

  const queryAccessChannel = typeof accessChannel === 'string' ? accessChannel : undefined
  const isConversationAccessChannel =
    queryAccessChannel === AccessChannel.ConversationNoTx ||
    queryAccessChannel === AccessChannel.ConversationTx

  const faqEntryPath = getFaqEntryPath(faqEntry)

  return urlWithParams(HC_FAQ_PATH(faqEntryPath), {
    access_channel: isConversationAccessChannel ? queryAccessChannel : AccessChannel.HcTopics,
    thread_id: threadId,
  })
}

export const getFaqDataFromUrl = (faqEntryUrl: string) => {
  const match = faqEntryUrl.match(FAQ_ENTRY_PATH_REGEX)

  if (!match) {
    return undefined
  }

  return {
    path: match[1],
    faqEntryParentId: Number(match[2]) || null,
    faqEntryId: Number(match[3]) || null,
  }
}

export const getContentWithThreadId = (
  content: string,
  threadId: Array<string> | string | undefined,
) => {
  return content.replace(URL_REGEX, (url: string) => {
    if (!url.includes(HELP_CENTER_URL)) {
      return url
    }

    return urlWithParams(url, {
      access_channel: AccessChannel.HcTopics,
      thread_id: threadId,
    })
  })
}
