'use client'

import { useItemPlugins } from 'pages/Item/hooks'
import { ItemDto } from 'types/dtos'

import ItemPageMakeOfferActionPlugin from '../plugins/MakeOfferAction/ItemPageMakeOfferActionPlugin'
import ItemPageBuyButtonPlugin from '../plugins/BuyButton/ItemPageBuyButtonPlugin'

type Props = {
  item: ItemDto
}

const ItemStickyButtons = ({ item }: Props) => {
  const plugins = useItemPlugins(item)

  return (
    <div className="item-sticky-buttons" data-testid="item-sticky-buttons">
      {plugins?.makeOfferAction && (
        <ItemPageMakeOfferActionPlugin isStickyButton data={plugins.makeOfferAction} />
      )}
      {plugins?.buyButton && <ItemPageBuyButtonPlugin isStickyButton data={plugins.buyButton} />}
    </div>
  )
}

export default ItemStickyButtons
