import { ItemDto } from 'types/dtos'
import { getBumpableItemThumbnailFromDto } from 'data/utils/bump'

import { ItemPageBumpActionModel } from '../types'

export const getItemPageBumpActionPluginModel = (itemDto: ItemDto): ItemPageBumpActionModel => ({
  id: itemDto.id,
  userId: itemDto.user.id,
  title: itemDto.title,
  thumbnail: getBumpableItemThumbnailFromDto(itemDto.photos),
  isPromoted: itemDto.promoted,
  isReserved: itemDto.is_reserved,
  isClosed: itemDto.is_closed,
  canPushUp: itemDto.can_push_up,
})
