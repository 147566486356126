'use client'

import { Cell } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'
import { getItemStatus, getItemStatusMessage } from 'data/utils/item'
import { ItemAlertStatus } from 'constants/item'
import { CONVERSATION_URL } from 'constants/routes/inbox'
import ProcessingListingStatusBox from 'components/ProcessingListingStatusBox'
import DelayedPublicationStatusBox from 'components/DelayedPublicationStatusBox'
import useSession from 'hooks/useSession'

import { ItemPageItemStatusPluginModel } from '../types'

type Props = {
  data: ItemPageItemStatusPluginModel
}

const ItemStatus = ({ data: item }: Props) => {
  const translate = useTranslate()
  const currentUser = useSession().user
  const isViewingSelf = currentUser?.id === item.userId

  const status = getItemStatus(item, isViewingSelf)
  const itemStatusMessage = getItemStatusMessage(status)

  const showUnderReview = () => {
    const isUnderReview = item.itemAlert?.item_alert_type === ItemAlertStatus.UnderReview
    const isModerator = currentUser?.moderator || currentUser?.is_catalog_moderator

    if (isUnderReview && (isViewingSelf || isModerator)) return true

    return false
  }

  if (item.isProcessing && isViewingSelf) return <ProcessingListingStatusBox />

  if (item.itemAlert?.item_alert_type === ItemAlertStatus.DelayedPublication) {
    return <DelayedPublicationStatusBox />
  }

  if (item.itemAlert?.item_alert_type === ItemAlertStatus.ReplicaProof) return null

  if (!itemStatusMessage) return null

  const { theme, content } = itemStatusMessage

  const getStatusMessage = () => {
    const contentTranslationKey = String(content)

    if (!item.reservation?.info) return translate(contentTranslationKey)

    return (
      <>
        {translate(contentTranslationKey)}
        <span className="u-float-right">{item.reservation.info}</span>
      </>
    )
  }

  const isReservedBetweenUsers =
    item.userId === currentUser?.id || item.reservation?.to_whom_id === currentUser?.id

  const getStatusUrl = () => {
    if (!currentUser?.id) return undefined
    if (item.isClosed || !item.isReserved) return undefined
    if (!item.reservation?.user_msg_thread_id) return undefined
    if (!isReservedBetweenUsers) return undefined

    return CONVERSATION_URL(item.reservation.user_msg_thread_id)
  }

  const cellTheme = showUnderReview() ? 'amplified' : theme

  if (status === 'reserved') {
    return (
      <Cell
        chevron={isReservedBetweenUsers}
        type={Cell.Type.Navigating}
        url={getStatusUrl()}
        theme={cellTheme}
        testId="item-status"
      >
        {getStatusMessage()}
      </Cell>
    )
  }

  return (
    <Cell url={getStatusUrl()} theme={cellTheme} testId="item-status">
      {getStatusMessage()}
    </Cell>
  )
}

export default ItemStatus
