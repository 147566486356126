'use client'

import { Badge, Spacer, Text } from '@vinted/web-ui'
import { useIntl } from 'react-intl'

import { formatCurrencyAmount } from 'libs/utils/formatString'
import { ItemBadgeDto } from 'types/dtos'
import { CurrencyAmountModel } from 'types/models'

type Props = {
  badge: ItemBadgeDto | null
  price: CurrencyAmountModel
  offerPrice: CurrencyAmountModel | null
  discountedPrice: CurrencyAmountModel | null
  isItemOwner: boolean
}

// TODO: if similar functionality is used elsewhere, move up the component into a separate file.
const Price = ({
  price,
  isItemOwner,
  ...props
}: Pick<Props, 'price' | 'isItemOwner'> & Omit<ComponentProps<typeof Text>, 'text'>) => {
  const { locale } = useIntl()

  return (
    <Text type={Text.Type.Subtitle} {...props} text={formatCurrencyAmount(price, locale)} as="p" />
  )
}

const ItemPriceHeading = ({ badge, price, discountedPrice, offerPrice, isItemOwner }: Props) => {
  const hasSpecialOffer =
    offerPrice &&
    offerPrice?.amount !== discountedPrice?.amount &&
    offerPrice?.amount !== price.amount

  const renderPriceField = () => {
    if (hasSpecialOffer && offerPrice)
      return <Price as="p" price={offerPrice} isItemOwner={isItemOwner} />

    if (!discountedPrice) return <Price as="p" price={price} isItemOwner={isItemOwner} />

    return (
      <div className="u-flexbox u-align-items-center">
        <Price as="p" price={discountedPrice} isItemOwner={isItemOwner} />
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
        <Price
          as="p"
          price={price}
          type={Text.Type.Subtitle}
          strikethrough
          isItemOwner={isItemOwner}
        />
      </div>
    )
  }

  const renderBadge = () => {
    if (!badge || hasSpecialOffer) return null

    return (
      <>
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
        <div className="u-flexbox u-align-items-center">
          <Badge content={badge.body} theme={badge.theme} />
        </div>
      </>
    )
  }

  return (
    <div className="u-flexbox u-align-items-center" data-testid="item-price">
      {renderPriceField()}
      {renderBadge()}
    </div>
  )
}

export default ItemPriceHeading
