import { ItemDto } from 'types/dtos'

import { ItemPageClosetTitlePluginModel } from './types'

export const getItemPageClosetTitlePluginModel = (
  itemDto: ItemDto,
): ItemPageClosetTitlePluginModel => ({
  itemCount: itemDto.user.item_count,
  itemUserId: itemDto.user.id,
})
