import { useMemo } from 'react'

import { ItemDto } from 'types/dtos'

import { ItemPageEditActionModel } from '../plugins/EditAction/types'
import { ItemPageDeleteActionModel } from '../plugins/DeleteAction/types'
import { ItemPageHideActionModel } from '../plugins/HideAction/types'
import { ItemPageMakeOfferActionPluginModel } from '../plugins/MakeOfferAction/types'
import { ItemPageGalleryPluginModel } from '../plugins/Gallery/types'
import { ItemPageBuyerProtectionInfoPluginModel } from '../plugins/BuyerProtectionInfo/types'
import { ItemPageClosetPluginDto } from '../plugins/Closet/types'
import { ItemPageShippingPricePluginModel } from '../plugins/ShippingPrice/types'
import { ItemPagePricingPluginModel } from '../plugins/Pricing/types'
import { ItemPageDescriptionPluginModel } from '../plugins/Description/types'
import { ItemPageBreadcrumbsPluginModel } from '../plugins/Breadcrumbs/types'
import { ItemPageSimilarItemsPluginModel } from '../plugins/SimilarItems/types'
import { ItemPageAskSellerActionPluginModel } from '../plugins/AskSellerAction/types'
import { ItemPagePerformanceBlockModel } from '../plugins/PerformanceBlock/types'
import { ItemPageAlertsPluginModel } from '../plugins/Alerts/types'
import { ItemPageOfflineVerificationStatusPluginModel } from '../plugins/OfflineVerificationStatus/types'
import { ItemPageOfflineVerificationBlockPluginModel } from '../plugins/OfflineVerificationBlock/types'
import { ItemPageShopBundlesBlockPluginModel } from '../plugins/ShopBundlesBlock/types'
import { ItemPageMarkAsReservedButtonPluginModel } from '../plugins/MarkAsReservedButton/types'
import { ItemPageMarkAsSoldButtonPluginModel } from '../plugins/MarkAsSoldButton/types'
import { ItemPageBuyButtonModel } from '../plugins/BuyButton/types'
import { ItemPageTransparencyLawPluginModel } from '../plugins/TransparencyLaw/types'
import { ItemPageLegalDpoFootnotePluginModel } from '../plugins/LegalDpoFootnote/types'
import { ItemPageReportButtonPluginModel } from '../plugins/ReportButton/types'
import { ItemPageAdminSectionPluginModel } from '../plugins/AdminSection/types'
import { ItemPageItemStatusPluginModel } from '../plugins/ItemStatus/types'
import { ItemPageClosetTitlePluginModel } from '../plugins/ClosetTitle/types'
import { ItemPageElectronicsVerificationBlockPluginModel } from '../plugins/ElectronicsVerificationBlock/types'
import { ItemPageBumpActionModel } from '../plugins/BumpAction/types'
import { ItemPageUserInfoHeaderPluginDto } from '../plugins/UserInfoHeader/types'
import { ItemPageUserInfoSellerBadgesPluginDto } from '../plugins/UserInfoSellerBadges/types'
import { ItemPageUserInfoDetailsPluginDto } from '../plugins/UserInfoDetails/types'
import { ItemPageUserInfoActionsPluginDto } from '../plugins/UserInfoActions/types'

import { getItemPageEditActionPluginModel } from '../plugins/EditAction/transformers'
import { getItemPageDeleteActionPluginModel } from '../plugins/DeleteAction/transformers'
import { getItemPageHideActionPluginModel } from '../plugins/HideAction/transformers'
import { getItemPageGalleryPluginModel } from '../plugins/Gallery/transformers'
import { getItemPageClosetPluginDto } from '../plugins/Closet/transformers'
import { getItemPageBuyerProtectionInfoPluginModel } from '../plugins/BuyerProtectionInfo/transformers'
import { getItemPageMakeOfferActionPluginModel } from '../plugins/MakeOfferAction/transformers'
import { getItemPageShippingPricePluginModel } from '../plugins/ShippingPrice/transformers'
import { getItemPagePricingPluginModel } from '../plugins/Pricing/transformers'
import { getItemPageDescriptionPluginModelDeprecated } from '../plugins/Description/transformers'
import { getItemPageBreadcrumbsPluginModel } from '../plugins/Breadcrumbs/transformers'
import { getItemPageSimilarItemsPluginDto } from '../plugins/SimilarItems/transformers'
import { getItemPageAskSellerActionPluginModel } from '../plugins/AskSellerAction/transformers'
import { getItemPagePerformanceBlockPluginModel } from '../plugins/PerformanceBlock/transformers'
import { getItemAlertsPluginModel } from '../plugins/Alerts/transformers'
import { getItemPageOfflineVerificationStatusPluginModel } from '../plugins/OfflineVerificationStatus/transformers'
import { getItemPageOfflineVerificationBlockPluginModel } from '../plugins/OfflineVerificationBlock/transformers'
import { getItemPageShopBundlesBlockPluginModel } from '../plugins/ShopBundlesBlock/transformers'
import { ItemPageClosetCountdownActionPluginDto } from '../plugins/ClosetCountdownAction/types'
import { getItemPageClosetCountdownActionPluginDto } from '../plugins/ClosetCountdownAction/transformers'
import { getItemPageMarkAsReservedButtonPluginModel } from '../plugins/MarkAsReservedButton/transformers'
import { getItemPageMarkAsSoldButtonPluginModel } from '../plugins/MarkAsSoldButton/transformers'
import { getItemPageBuyButtonPluginModel } from '../plugins/BuyButton/transformers'
import { getItemPageBumpActionPluginModel } from '../plugins/BumpAction/transformers'
import { getItemPageTransparencyLawPluginModel } from '../plugins/TransparencyLaw/transformers'
import { getItemPageLegalDpoFootnotePluginModel } from '../plugins/LegalDpoFootnote/transformers'
import { getItemPageReportButtonPluginModel } from '../plugins/ReportButton/transformers'
import { getItemPageAdminSectionPluginModel } from '../plugins/AdminSection/transformers'
import { getItemPageItemStatusPluginModel } from '../plugins/ItemStatus/transformers'
import { getItemPageClosetTitlePluginModel } from '../plugins/ClosetTitle/transformers'
import { getItemPageElectronicsVerificationBlockPluginModel } from '../plugins/ElectronicsVerificationBlock/transformers'
import { getItemPageUserInfoHeaderPluginDto } from '../plugins/UserInfoHeader/transformers'
import { getItemPageUserInfoSellerBadgesPluginDto } from '../plugins/UserInfoSellerBadges/transformers'
import { getItemPageUserInfoDetailsPluginDto } from '../plugins/UserInfoDetails/transformers'
import { getItemPageUserInfoActionsPluginModel } from '../plugins/UserInfoActions/transformers'

import { logItemPageError } from '../utils/observability'

export type Plugins = {
  editAction: ItemPageEditActionModel | null
  deleteAction: ItemPageDeleteActionModel | null
  hideAction: ItemPageHideActionModel | null
  bumpAction: ItemPageBumpActionModel | null
  makeOfferAction: ItemPageMakeOfferActionPluginModel | null
  gallery: ItemPageGalleryPluginModel | null
  closetCountdownAction?: ItemPageClosetCountdownActionPluginDto | null
  buyerProtectionInfo: ItemPageBuyerProtectionInfoPluginModel | null
  closet: ItemPageClosetPluginDto | null
  shippingPrice: ItemPageShippingPricePluginModel | null
  pricing: ItemPagePricingPluginModel | null
  description: ItemPageDescriptionPluginModel | null
  breadcrumbs: ItemPageBreadcrumbsPluginModel | null
  similarItems: ItemPageSimilarItemsPluginModel | null
  askSellerAction: ItemPageAskSellerActionPluginModel | null
  performanceBlock: ItemPagePerformanceBlockModel | null
  alerts: ItemPageAlertsPluginModel | null
  offlineVerificationStatus: ItemPageOfflineVerificationStatusPluginModel | null
  offlineVerificationBlock: ItemPageOfflineVerificationBlockPluginModel | null
  shopBundlesBlock: ItemPageShopBundlesBlockPluginModel | null
  itemStatus: ItemPageItemStatusPluginModel | null
  markAsReservedButton: ItemPageMarkAsReservedButtonPluginModel | null
  markAsSoldButton: ItemPageMarkAsSoldButtonPluginModel | null
  buyButton: ItemPageBuyButtonModel | null
  transparencyLaw: ItemPageTransparencyLawPluginModel | null
  legalDpoFootnote: ItemPageLegalDpoFootnotePluginModel | null
  reportButton: ItemPageReportButtonPluginModel | null
  adminSection: ItemPageAdminSectionPluginModel | null
  closetTitle: ItemPageClosetTitlePluginModel | null
  electronicsVerificationBlock: ItemPageElectronicsVerificationBlockPluginModel | null
  userInfoHeader: ItemPageUserInfoHeaderPluginDto | null
  userInfoSellerBadges: ItemPageUserInfoSellerBadgesPluginDto | null
  userInfoDetails: ItemPageUserInfoDetailsPluginDto | null
  userInfoActions: ItemPageUserInfoActionsPluginDto | null
}

export const getPlugins = (itemDto: ItemDto): Plugins => {
  const withCatch = <T>(getPluginModel: (itemDto: ItemDto) => T, pluginName: string): T | null => {
    try {
      return getPluginModel(itemDto)
    } catch (error) {
      logItemPageError(error, 'plugin_transformation_failed', `plugin: ${pluginName}`)

      return null
    }
  }

  return {
    editAction: withCatch(getItemPageEditActionPluginModel, 'edit'),
    deleteAction: withCatch(getItemPageDeleteActionPluginModel, 'delete'),
    hideAction: withCatch(getItemPageHideActionPluginModel, 'hide'),
    bumpAction: withCatch(getItemPageBumpActionPluginModel, 'bump'),
    makeOfferAction: withCatch(getItemPageMakeOfferActionPluginModel, 'make_offer'),
    gallery: withCatch(getItemPageGalleryPluginModel, 'gallery'),
    closetCountdownAction: withCatch(getItemPageClosetCountdownActionPluginDto, 'closet_countdown'),
    buyerProtectionInfo: withCatch(
      getItemPageBuyerProtectionInfoPluginModel,
      'buyer_protection_info',
    ),
    closet: withCatch(getItemPageClosetPluginDto, 'closet'),
    shippingPrice: withCatch(getItemPageShippingPricePluginModel, 'shipping_price'),
    pricing: withCatch(getItemPagePricingPluginModel, 'pricing'),
    description: withCatch(getItemPageDescriptionPluginModelDeprecated, 'description'),
    breadcrumbs: withCatch(getItemPageBreadcrumbsPluginModel, 'breadcrumbs'),
    similarItems: withCatch(getItemPageSimilarItemsPluginDto, 'similar_items'),
    askSellerAction: withCatch(getItemPageAskSellerActionPluginModel, 'ask_seller'),
    performanceBlock: withCatch(getItemPagePerformanceBlockPluginModel, 'performance'),
    alerts: withCatch(getItemAlertsPluginModel, 'alerts'),
    offlineVerificationStatus: withCatch(
      getItemPageOfflineVerificationStatusPluginModel,
      'offline_verification_status',
    ),
    offlineVerificationBlock: withCatch(
      getItemPageOfflineVerificationBlockPluginModel,
      'offline_verification_block',
    ),
    shopBundlesBlock: withCatch(getItemPageShopBundlesBlockPluginModel, 'shop_bundles'),
    itemStatus: withCatch(getItemPageItemStatusPluginModel, 'item_status'),
    markAsReservedButton: withCatch(getItemPageMarkAsReservedButtonPluginModel, 'mark_as_reserved'),
    markAsSoldButton: withCatch(getItemPageMarkAsSoldButtonPluginModel, 'mark_as_sold'),
    buyButton: withCatch(getItemPageBuyButtonPluginModel, 'buy'),
    transparencyLaw: withCatch(getItemPageTransparencyLawPluginModel, 'transparency_law'),
    legalDpoFootnote: withCatch(getItemPageLegalDpoFootnotePluginModel, 'legal_dpo_footnote'),
    reportButton: withCatch(getItemPageReportButtonPluginModel, 'report'),
    adminSection: withCatch(getItemPageAdminSectionPluginModel, 'admin'),
    closetTitle: withCatch(getItemPageClosetTitlePluginModel, 'closet_title'),
    electronicsVerificationBlock: withCatch(
      getItemPageElectronicsVerificationBlockPluginModel,
      'electronics_verification_block',
    ),
    userInfoHeader: withCatch(getItemPageUserInfoHeaderPluginDto, 'user_info_header'),
    userInfoSellerBadges: withCatch(
      getItemPageUserInfoSellerBadgesPluginDto,
      'user_info_seller_badges',
    ),
    userInfoDetails: withCatch(getItemPageUserInfoDetailsPluginDto, 'user_info_details'),
    userInfoActions: withCatch(getItemPageUserInfoActionsPluginModel, 'user_info_actions'),
  }
}

export const useItemPlugins = (itemDto: ItemDto): Plugins | null => {
  const pluginDtos = useMemo(() => {
    return getPlugins(itemDto)
  }, [itemDto])

  return pluginDtos
}
