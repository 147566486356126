'use client'

import { ReactNode, useMemo } from 'react'

import { EscrowFeesModel } from 'types/models/escrow-fees'

import EscrowFeesContext from './EscrowFeesContext'

type Props = {
  escrowFees?: EscrowFeesModel | null
  children: ReactNode
}

const EscrowFeesProvider = ({ escrowFees, children }: Props) => {
  const escrowFeesMemo = useMemo(
    () => ({
      escrowFees,
    }),
    [escrowFees],
  )

  return <EscrowFeesContext.Provider value={escrowFeesMemo}>{children}</EscrowFeesContext.Provider>
}

export default EscrowFeesProvider
