'use client'

import UserBadge from 'components/UserBadge'

import { ItemPageUserInfoSellerBadgesPluginDto } from './types'

type Props = {
  data: ItemPageUserInfoSellerBadgesPluginDto
}

const ItemPageUserInfoSellerBadgesPlugin = ({ data }: Props) => {
  const { seller_badges } = data

  if (!seller_badges?.length) return null

  return <UserBadge />
}

export default ItemPageUserInfoSellerBadgesPlugin
