'use client'

import { Button } from '@vinted/web-ui'

import { BumpCheckout } from 'components/Bumps'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import useStartBumpCheckout from 'hooks/useStartBumpCheckout'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent } from 'libs/common/event-tracker/events'
import { ItemPageBumpActionModel } from 'pages/Item/plugins/BumpAction/types'
import { BumpableItemModel } from 'types/models'

type Props = {
  item: ItemPageBumpActionModel
}

const ItemBumpButton = ({ item }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('item.actions')
  const { startCheckout } = useStartBumpCheckout()

  function isPromoteButtonVisible() {
    const isButtonDisabled = item?.isPromoted || !item.canPushUp

    if (item?.isClosed || item?.isReserved) return false

    return !isButtonDisabled
  }

  function getBumpableItemDataFromItemActionModel(
    itemModel: ItemPageBumpActionModel,
  ): BumpableItemModel {
    return {
      id: itemModel.id,
      title: itemModel.title,
      thumbnail: itemModel.thumbnail,
    }
  }

  function handleOnClick() {
    startCheckout(getBumpableItemDataFromItemActionModel(item))

    track(
      clickEvent({
        target: ClickableElement.PushUpItem,
        targetDetails: JSON.stringify({
          item_id: item.id,
        }),
        screen: 'item',
      }),
    )
  }

  return (
    <>
      {isPromoteButtonVisible() && (
        <Button
          text={translate('bump')}
          size={Button.Size.Medium}
          styling={Button.Styling.Filled}
          onClick={handleOnClick}
          testId="item-bump-button"
        />
      )}
      <BumpCheckout />
    </>
  )
}

export default ItemBumpButton
