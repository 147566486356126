'use client'

import { Card, EmptyState, Icon } from '@vinted/web-ui'
import { GraphEmptyState96 } from '@vinted/multichrome-icons'

import useTranslate from 'hooks/useTranslate'

const ItemPerformanceEmptyState = () => {
  const translate = useTranslate('item_performance.empty_state')

  return (
    <Card>
      <div className="u-ui-padding-vertical-x-large" data-testid="bump-statistic-empty-state">
        <EmptyState
          title={translate('title')}
          body={translate('body')}
          icon={<Icon name={GraphEmptyState96} />}
        />
      </div>
    </Card>
  )
}

export default ItemPerformanceEmptyState
