import clientSideMetrics from 'libs/common/client-side-metrics'
import { logError } from 'libs/utils/window'
import { MS_PER_SECOND } from 'constants/date'

const PREFIX = 'Item_page'
const ITEM_PAGE_LOAD_DURATION_METRIC_NAME = 'item_page_load_duration'
const ITEM_PAGE_LOAD_COUNT_METRIC_NAME = 'item_page_load_count'
const BUCKETS = [0.05, 0.2, 0.4, 0.8, 1.2, 1.6, 2, 3.5, 5, 10]

export const logItemPageError = (error: Error | null, feature?: string, extra?: string) => {
  if (!(error instanceof Error)) return

  const loggedFeature = feature ? `${PREFIX}_${feature}` : PREFIX

  logError(error, { feature: loggedFeature, extra })
}

export const incrementPageLoadCounter = (state: 'succeeded' | 'failed' | 'initiated') => {
  clientSideMetrics.counter(ITEM_PAGE_LOAD_COUNT_METRIC_NAME, { state }).increment()
}

export const observePageLoadTime = (state: 'succeeded' | 'failed', itemLoadTime: number) => {
  clientSideMetrics
    .histogram(ITEM_PAGE_LOAD_DURATION_METRIC_NAME, { state }, BUCKETS)
    .observe(itemLoadTime / MS_PER_SECOND)
}
