'use client'

import { useMemo, useRef } from 'react'
import { InView } from 'react-intersection-observer'
import { Label, Text } from '@vinted/web-ui'

import { ContentSource } from 'constants/tracking/content-sources'
import useFetch from 'hooks/useFetch'
import useTranslate from 'hooks/useTranslate'
import { getSimilarItemsById } from 'data/api'
import ContentLoader from 'components/ContentLoader'
import ItemViewItems from 'components/ItemViewItems'
import { transformSimilarItemDtoToProductItem } from 'data/transformers'

import { ItemPageSimilarItemsPluginModel, SimilarItemModel } from './types'

type Props = {
  data?: ItemPageSimilarItemsPluginModel
}

const ItemPageSimilarItemsPlugin = ({ data }: Props) => {
  const { fetch: fetchSimilarItems, data: similarItemsData } = useFetch(getSimilarItemsById)
  const hasFetched = useRef(false)

  const translate = useTranslate('item')

  const items: Array<SimilarItemModel> = useMemo(() => {
    return similarItemsData?.items.map(transformSimilarItemDtoToProductItem) || []
  }, [similarItemsData])

  if (!data) return null

  const handleInViewChange = (inView: boolean) => {
    if (!inView || hasFetched.current) return

    fetchSimilarItems({ itemId: data.itemId })
    hasFetched.current = true
  }

  const renderLoader = () =>
    !similarItemsData && (
      <div className="u-ui-margin-top-medium">
        <ContentLoader testId="item-feed-loader" />
      </div>
    )

  return (
    <InView as="section" data-testid="item-page-similar-items-plugin" onChange={handleInViewChange}>
      {renderLoader()}
      {items.length > 0 && (
        <>
          <Label
            text={
              <Text as="h3" type={Text.Type.Subtitle}>
                {translate('similar_items_title')}
              </Text>
            }
          />
          <ItemViewItems
            items={items}
            contentSource={ContentSource.SimilarItems}
            itemTestId="similar-item"
          />
        </>
      )}
    </InView>
  )
}

export default ItemPageSimilarItemsPlugin
