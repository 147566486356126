'use client'

import { useState } from 'react'
import { Text, Cell, Button, Icon } from '@vinted/web-ui'
import { InfoCircle16 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'

import ProcessingListingStatusModal from './ProcessingListingStatusModal'

const ProcessingListingStatusBox = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const translate = useTranslate('processing_listing.status_box')

  const handleModalClose = () => {
    setIsModalVisible(false)
  }

  const handleInfoClick = () => {
    setIsModalVisible(true)
  }

  return (
    <>
      <Cell theme="amplified" testId="item-status-processing">
        <div className="u-flexbox u-align-items-center">
          <Text as="h2" text={translate('title')} type={Text.Type.Title} theme="inherit" inline />
          <Button
            icon={<Icon name={InfoCircle16} color={Icon.Color.GreyscaleLevel5} />}
            size={Button.Size.Small}
            styling={Button.Styling.Flat}
            onClick={handleInfoClick}
            title={translate('a11y.learn_more')}
            inline
          />
        </div>
      </Cell>
      <ProcessingListingStatusModal show={isModalVisible} onClose={handleModalClose} />
    </>
  )
}

export default ProcessingListingStatusBox
