export enum PluginName {
  Feedbacks = 'feedbacks',
  Attributes = 'attributes',
  Summary = 'summary',
  Description = 'description',
  SellerBadgesInfo = 'seller_badges_info',
  OtherUserItemsHeader = 'other_user_items_header',
}

export enum PluginType {
  Feedbacks = 'feedbacks',
  Attributes = 'attributes',
  Summary = 'summary',
  Description = 'description',
  SellerBadgesInfo = 'seller_badges_info',
  HorizontalItemsBlockHeader = 'horizontal_items_block_header',
  HorizontalItemsBlock = 'horizontal_items_block',
}

export enum SectionType {
  Feed = 'feed',
  Base = 'base',
}
