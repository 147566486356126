'use client'

import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Dialog } from '@vinted/web-ui'

import { modifyItemVisibility } from 'data/api'
import { getUser } from 'state/session/selectors'
import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import { reloadPage } from 'libs/utils/window'
import { clickEvent } from 'libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ResponseCode } from 'data/api/response-codes'

import { ItemPageHideActionModel } from './types'

type Props = {
  hideActionPluginModel: ItemPageHideActionModel | undefined
}

const ItemPageHideActionPlugin = ({ hideActionPluginModel }: Props) => {
  const translate = useTranslate('item.actions')
  const user = useSelector(getUser)
  const currentUserId = user?.id

  const { track } = useTracking()
  const [isLoading, setIsLoading] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)

  if (!hideActionPluginModel) return null

  const { id, isItemHidden, canEdit, isTransactionPermitted, userId } = hideActionPluginModel
  const isViewingOwnItem = userId === currentUserId

  const handleButtonClick = async (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
  ) => {
    if (isLoading) {
      event.preventDefault()

      return
    }

    if (!user) return

    track(
      clickEvent({
        target: ClickableElement.HideItem,
        screen: Screen.Item,
        targetDetails: JSON.stringify({
          item_id: id,
          user_id: user?.id,
        }),
      }),
    )

    setIsLoading(true)

    const { code } = await modifyItemVisibility(id, !isItemHidden)

    if (code === ResponseCode.Ok) {
      reloadPage()
    } else {
      setIsLoading(false)
      setShowErrorModal(true)
    }
  }

  if (!isViewingOwnItem) return null
  if (!user || user.is_on_holiday) return null
  if (!isTransactionPermitted || !canEdit) return null

  return (
    <>
      <Dialog
        show={showErrorModal}
        title={translate('error_modal.title')}
        closeOnOverlay
        defaultCallback={reloadPage}
        actions={[
          {
            text: translate('error_modal.actions.close'),
            style: Dialog.ActionStyling.Filled,
            callback: reloadPage,
          },
        ]}
        testId="item-hide-button-error-modal"
      />
      <Button
        isLoading={isLoading}
        disabled={isLoading}
        text={translate(isItemHidden ? 'unhide' : 'hide')}
        onClick={handleButtonClick}
        size={Button.Size.Medium}
        testId="item-hide-button"
      />
    </>
  )
}

export default ItemPageHideActionPlugin
