import { ItemDto } from 'types/dtos'

import { ItemPageBreadcrumbsPluginModel } from './types'

export const getItemPageBreadcrumbsPluginModel = (
  itemDto: ItemDto,
): ItemPageBreadcrumbsPluginModel => ({
  brandId: itemDto.brand_dto?.id,
  catalogId: itemDto.catalog_id,
})
