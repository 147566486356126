'use client'

import { Button, Container, Dialog, Spacer, Text } from '@vinted/web-ui'

import { SELLING_HELP_URL } from 'constants/routes'
import useTranslate from 'hooks/useTranslate'

type Props = {
  show: boolean
  onClose: () => void
}

const DelayedPublicationModal = ({ show, onClose }: Props) => {
  const translate = useTranslate('delayed_publication.modal')

  const renderTitle = () => (
    <Text
      as="h1"
      text={translate('title')}
      alignment={Text.Alignment.Left}
      type={Text.Type.Heading}
    />
  )

  const renderBody = () => (
    <Text
      as="span"
      text={translate('body', {
        'catalog-rules-link': chunks => (
          <a href={SELLING_HELP_URL} target="_blank" rel="noopener noreferrer">
            {chunks}
          </a>
        ),
      })}
      html
    />
  )

  const renderActions = () => (
    <Button
      text={translate('actions.close')}
      onClick={onClose}
      styling={Button.Styling.Filled}
      testId="delayed-publication-modal-close-button"
    />
  )

  return (
    <Dialog show={show} testId="delayed-publication-modal">
      <Container>
        {renderTitle()}
        <Spacer size={Spacer.Size.Large} />
        {renderBody()}
        <Spacer size={Spacer.Size.Large} />
        {renderActions()}
      </Container>
    </Dialog>
  )
}

export default DelayedPublicationModal
