import { createContext } from 'react'

import { EscrowFeesModel } from 'types/models/escrow-fees'

export type EscrowFeesContextType = {
  escrowFees?: EscrowFeesModel | null
}

const EscrowFeesContext = createContext<EscrowFeesContextType>({ escrowFees: null })

export default EscrowFeesContext
