'use client'

import { useContext, useEffect, useRef, useState } from 'react'
import { Button } from '@vinted/web-ui'

import useFetch from 'hooks/useFetch'
import useRefUrl from 'hooks/useRefUrl'
import useTracking from 'hooks/useTracking'
import useLocation from 'hooks/useLocation'
import useSession from 'hooks/useSession'
import useTranslate from 'hooks/useTranslate'

import { UiState } from 'constants/ui'
import { SIGNUP_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'
import { CONVERSATION_URL } from 'constants/routes/inbox'
import { ConversationInitiatorType } from 'constants/conversation'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import { createItemConversationThread } from 'data/api'
import { transformConversationResponse } from 'state/conversation/transformers'

import { urlWithParams } from 'libs/utils/url'
import { navigateToPage } from 'libs/utils/window'
import { getSearchSessionData } from 'libs/utils/search'
import { getHomepageSessionIdFromUrl } from 'libs/utils/homepage'
import { buyerWantItemEvent, clickEvent } from 'libs/common/event-tracker/events'

import { ItemPageAskSellerActionPluginModel } from './types'
import { ShippingDetailsContext } from '../../containers/ShippingDetailsProvider'
import useClientSideOnly from '../../../../../next/hooks/useClientSideOnly'

type Props = {
  data: ItemPageAskSellerActionPluginModel
}

const ItemPageAskSellerActionPlugin = ({ data }: Props) => {
  const translate = useTranslate('item')
  const { track } = useTracking()
  const refUrl = useRefUrl()
  const { searchParams } = useLocation()
  const isClientSide = useClientSideOnly()

  const { user } = useSession()
  const currentUserId = user?.id
  const isUserAuthenticated = !!user

  const [uiState, setUiState] = useState(UiState.Idle)

  const referrerScreen = searchParams.referrer?.toString()
  const searchSessionData = getSearchSessionData()
  const searchSessionIdRef = useRef(
    referrerScreen === Screen.Catalog ? searchSessionData.searchSessionId : null,
  )

  const { shippingDetails } = useContext(ShippingDetailsContext)

  const { fetch: createConversation, transformedData: conversation } = useFetch(
    createItemConversationThread,
    response => transformConversationResponse(response.conversation),
  )

  useEffect(() => {
    if (!conversation?.id) return
    if (uiState !== UiState.Pending) return

    track(
      buyerWantItemEvent({
        itemId: data.itemId,
        transactionId: Number(conversation.id),
        button: 'message',
        globalSearchSessionId: searchSessionData.globalSearchSessionId,
        searchSessionId: searchSessionIdRef.current,
        homepageSessionId: getHomepageSessionIdFromUrl(),
      }),
    )

    navigateToPage(CONVERSATION_URL(conversation.id))
  }, [conversation?.id, data.itemId, searchSessionData.globalSearchSessionId, track, uiState])

  if (!data) return null

  const {
    canBuy,
    canInstantBuy,
    isClosed,
    isHidden,
    isReserved,
    itemId,
    itemUserId,
    reservedForUserId,
  } = data

  const isItemReservedForBuyer = reservedForUserId === currentUserId
  const isViewingOwnItem = itemUserId === currentUserId

  const showPlugin = !isClosed && !isViewingOwnItem

  if (!showPlugin) return null

  const handleClick = () => {
    setUiState(UiState.Pending)

    track(
      clickEvent({
        target: ClickableElement.AskSellerButton,
        targetDetails: JSON.stringify({
          item_id: itemId,
        }),
      }),
    )

    if (!conversation?.id) {
      createConversation({
        itemId: itemId.toString(),
        receiverId: itemUserId.toString(),
        initiator: ConversationInitiatorType.AskSeller,
      })
    }
  }

  const getUrl = () => {
    if (isUserAuthenticated) return undefined

    const urlQuery: Parameters<typeof urlWithParams>[1] = {
      button_name: 'message',
      ch: 'item',
      receiver_id: itemUserId,
      ref_url: refUrl,
      source: 'wantit',
    }

    if (isClientSide) {
      urlQuery.global_search_session_id = searchSessionData.globalSearchSessionId
    }

    return urlWithParams(SIGNUP_URL, urlQuery)
  }

  const canTransact =
    canBuy &&
    canInstantBuy &&
    (!isReserved || isItemReservedForBuyer) &&
    !isHidden &&
    !!shippingDetails

  return (
    <Button
      text={translate('actions.ask_seller')}
      onClick={handleClick}
      styling={canTransact ? Button.Styling.Outlined : Button.Styling.Filled}
      testId="ask-seller-button"
      url={getUrl()}
    />
  )
}

export default ItemPageAskSellerActionPlugin
