/* eslint id-length: 0 */

'use client'

import { MouseEvent } from 'react'
import classNames from 'classnames'

import { PerformanceChartDataPoint as DataPoint } from 'types/components'

type Props = {
  cx?: number
  cy?: number
  stroke?: string
  strokeWidth?: number
  payload?: DataPoint
  index?: number
  value?: number | string | null
  activePoint: number | null | undefined
  interactive: boolean
  onClick: (payload?: DataPoint, index?: number) => void
  testId?: string
}

const Dot = ({
  cx,
  cy,
  stroke,
  strokeWidth,
  payload,
  index,
  value,
  activePoint,
  interactive,
  onClick,
  testId,
}: Props) => {
  const handleClick = (event: MouseEvent<SVGCircleElement>) => {
    event.stopPropagation()
    onClick(payload, index)
  }

  const isActive = activePoint === index

  if (value === null || value === undefined) return null

  const circleProps = {
    r: isActive ? 8 : 4,
    strokeWidth: isActive ? 4 : strokeWidth,
  }

  const cssClasses = classNames({ 'u-cursor-pointer': interactive })

  return (
    <circle
      {...circleProps}
      stroke={stroke}
      fill="#fff"
      cx={cx}
      cy={cy}
      onClick={handleClick}
      className={cssClasses}
      data-testid={testId}
    />
  )
}

export default Dot
