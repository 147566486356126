'use client'

import { Label, Text } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

import { ItemPageClosetTitlePluginModel } from './types'

type Props = {
  data: ItemPageClosetTitlePluginModel | undefined
}

const ItemPageClosetTitlePlugin = ({ data }: Props) => {
  const translate = useTranslate('item')

  if (!data) return null
  if (data.itemCount <= 1) return null

  return (
    <Label
      text={
        <Text as="h3" type={Text.Type.Subtitle}>
          {translate('owner_items_title', { count: data.itemCount })}
        </Text>
      }
    />
  )
}

export default ItemPageClosetTitlePlugin
