import { ItemDto } from 'types/dtos'

import { ItemPageMarkAsSoldButtonPluginModel } from './types'

export const getItemPageMarkAsSoldButtonPluginModel = (
  itemDto: ItemDto,
): ItemPageMarkAsSoldButtonPluginModel => ({
  itemId: itemDto.id,
  transactionPermitted: itemDto.transaction_permitted,
  isClosed: itemDto.is_closed,
  userId: itemDto.user.id,
})
