'use client'

import { Spacer, Text } from '@vinted/web-ui'

import { navigateToPage } from 'libs/utils/window'
import { itemAttributesClickEvent } from 'libs/common/event-tracker/events'
import useTracking from 'hooks/useTracking'
import SeparatedList from 'components/SeparatedList'

import {
  ItemPageSummaryPluginModel,
  SummaryLineModel,
  SummaryNavigationalElementModel,
  SummaryTextElementModel,
} from './types'

const ItemPageSummaryPlugin = ({ lines, itemId }: ItemPageSummaryPluginModel) => {
  const { track } = useTracking()

  const onNavigationTextClick = (element: SummaryNavigationalElementModel) => () => {
    track(
      itemAttributesClickEvent({
        itemId: itemId.toString(),
        attributeCode: element.code,
        attributeValueId: element.id.toString(),
      }),
    )
    navigateToPage(element.url)
  }

  const renderSpacer = () => (
    <>
      <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} as="span" />
      <Text as="span" text="&middot;" type={Text.Type.Body} />
      <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} as="span" />
    </>
  )

  const renderElement = (
    element: SummaryTextElementModel | SummaryNavigationalElementModel,
    index: number,
  ) => {
    if (element.type === 'text') {
      return <Text key={`element-${index}`} text={element.value} type={element.style} as="span" />
    }
    if (element.type === 'navigational') {
      return (
        <a href={element.url} onClick={onNavigationTextClick(element)} key={`element-${index}`}>
          <Text clickable text={element.value} type={element.style} as="span" />
        </a>
      )
    }

    return null
  }

  const renderLine = (line: SummaryLineModel, lineIndex: number) => {
    return (
      <div key={`summary-line-${lineIndex}`} className={`summary-max-lines-${line.maxLines}`}>
        <SeparatedList separator={renderSpacer()}>
          {line.elements.map(renderElement).filter(element => element !== null)}
        </SeparatedList>
      </div>
    )
  }

  return (
    <div data-testid="item-page-summary-plugin">
      {lines.map(renderLine)}
      <Spacer size={Spacer.Size.Large} />
    </div>
  )
}

export default ItemPageSummaryPlugin
