'use client'

import useFeatureSwitch from 'hooks/useFeatureSwitch'
import useSession from 'hooks/useSession'

import ItemBumpButton from 'components/ItemBumpButton'
import BumpCheckoutProvider from 'components/Bumps/containers/BumpCheckoutProvider'

import { ItemPageBumpActionModel } from './types'

type Props = {
  bumpActionPluginModel: ItemPageBumpActionModel | undefined
}

const ItemPageBumpActionPlugin = ({ bumpActionPluginModel }: Props) => {
  const isPortalMergeStarted = useFeatureSwitch('portal_merge_source')
  const { user: currentUser } = useSession()

  const isViewingOwnItem = bumpActionPluginModel?.userId === currentUser?.id

  if (!bumpActionPluginModel || !isViewingOwnItem || isPortalMergeStarted) return null

  return (
    <BumpCheckoutProvider>
      <ItemBumpButton item={bumpActionPluginModel} />
    </BumpCheckoutProvider>
  )
}

export default ItemPageBumpActionPlugin
