import { ReactNode } from 'react'
import { Icon, Spacer } from '@vinted/web-ui'

type Props = {
  children: ReactNode
  iconName: ComponentProps<typeof Icon>['name']
  truncate?: boolean
}

const UserInfoLine = ({ children, iconName, truncate }: Props) => (
  <div className="u-flexbox u-align-items-baseline">
    <Icon name={iconName} color={Icon.Color.GreyscaleLevel4} aria={{ 'aria-hidden': 'true' }} />
    <Spacer orientation={Spacer.Orientation.Vertical} />
    <div className={truncate ? 'u-ellipsis' : ''}>{children}</div>
  </div>
)

export default UserInfoLine
