'use client'

import { useContext, useState, ReactNode } from 'react'
import { Cell, Spacer, Text, Icon } from '@vinted/web-ui'
import { ElectronicsBadgeMultichrome32 } from '@vinted/multichrome-icons'
import { useIntl } from 'react-intl'

import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import useSession from 'hooks/useSession'

import { formatCurrencyAmount } from 'libs/utils/formatString'
import { clickEvent } from 'libs/common/event-tracker/events'

import ElectronicsVerification from 'components/ElectronicsVerification'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import { ShippingDetailsContext } from '../../containers/ShippingDetailsProvider'
import { EscrowFeesContext } from '../../containers/EscrowFeesProvider'
import { ItemPageElectronicsVerificationBlockPluginModel } from './types'

type Props = {
  data: ItemPageElectronicsVerificationBlockPluginModel | undefined
}

const ItemPageElectronicsVerificationBlockPlugin = ({ data }: Props) => {
  const { user } = useSession()
  const { locale } = useIntl()
  const { shippingDetails } = useContext(ShippingDetailsContext)
  const { escrowFees } = useContext(EscrowFeesContext)

  const { track } = useTracking()

  const isViewingOwnItem = user?.id === data?.itemUserId
  const userSide = isViewingOwnItem ? 'seller' : 'buyer'

  const translate = useTranslate(`item.electronics_verification_service.${userSide}`)

  const [isModalOpen, setIsModalOpen] = useState(false)

  if (!shippingDetails) return null
  if (!data) return null

  const getFormattedVerificationFee = () => {
    const finalPrice = escrowFees?.electronicsVerification?.finalPrice

    return finalPrice && formatCurrencyAmount(finalPrice, locale)
  }

  const formattedVerificationFee = getFormattedVerificationFee()

  if (!formattedVerificationFee) return null

  const handleLearnMoreClick = () => {
    setIsModalOpen(true)

    const target = isViewingOwnItem
      ? ClickableElement.ElectronicsVerificationSeller
      : ClickableElement.ElectronicsVerificationBuyer

    track(clickEvent({ screen: Screen.Item, target }))
  }

  const howItWorksLink = {
    'how-it-works': (chunks: Array<ReactNode>) => (
      <span>
        <button type="button" onClick={handleLearnMoreClick}>
          <Text
            as="span"
            clickable
            text={chunks}
            type={Text.Type.Subtitle}
            testId="electronics-verification-block-how-it-works-link"
          />
        </button>
      </span>
    ),
  }

  return (
    <>
      <Spacer />
      <Cell
        styling={Cell.Styling.Tight}
        prefix={
          <Icon
            testId="electronics-verification-block-icon"
            name={ElectronicsBadgeMultichrome32}
            aria={{
              'aria-hidden': 'true',
            }}
          />
        }
        title={
          <Text
            as="h2"
            text={translate('title')}
            theme="amplified"
            type={Text.Type.Title}
            testId="electronics-verification-block-title"
          />
        }
        body={
          <>
            {!isViewingOwnItem && (
              <>
                <Text
                  as="h3"
                  text={translate('optional_price_text', { amount: formattedVerificationFee })}
                  theme="inherit"
                  type={Text.Type.Subtitle}
                  testId="electronics-verification-block-amount"
                />
                <Spacer />
              </>
            )}
            <Text
              as="h3"
              text={translate('body', howItWorksLink)}
              theme="inherit"
              type={Text.Type.Subtitle}
              testId="electronics-verification-block-description"
              html
            />
          </>
        }
      />
      <Spacer size={Spacer.Size.Medium} />
      <ElectronicsVerification
        screen={Screen.Item}
        show={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isViewingOwnItem={isViewingOwnItem}
        verificationFee={escrowFees?.electronicsVerification?.finalPrice}
      />
    </>
  )
}

export default ItemPageElectronicsVerificationBlockPlugin
