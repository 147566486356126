import { PropsWithChildren } from 'react'

import Toggleable from 'components/Toggleable'

import DropdownToggle from './DropdownToggle'
import DropdownMenuItem from './DropdownMenuItem'
import DropdownMenu from './DropdownMenu'
import DropdownMenuDescription from './DropdownMenuDescription'

type Props = PropsWithChildren<ComponentProps<typeof Toggleable>>

const Dropdown = ({ children, ...toggleableProps }: Props) => {
  return (
    <Toggleable closeOnOutsideClick {...toggleableProps}>
      <div className="u-position-relative u-cursor-default">{children}</div>
    </Toggleable>
  )
}

// TODO update component names
Dropdown.Content = DropdownMenu
Dropdown.ContentItem = DropdownMenuItem
Dropdown.ContentDescription = DropdownMenuDescription
Dropdown.Toggle = DropdownToggle

export default Dropdown
