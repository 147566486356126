import { noop } from 'lodash'
import { createContext, RefObject } from 'react'

type ContextType = {
  toggle: () => void
  close: () => void
  isVisible: boolean
  containerRef: RefObject<HTMLDivElement> | null
}

export const ToggleableContext = createContext<ContextType>({
  toggle: noop,
  close: noop,
  isVisible: false,
  containerRef: null,
})
