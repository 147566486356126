import { ItemPerformanceModel } from 'types/models'
import { ItemPerformanceDto } from 'types/dtos'

export const transformItemPerformance = (dto: ItemPerformanceDto): ItemPerformanceModel => ({
  id: dto.id,
  itemId: dto.item_id,
  impressions: dto.impressions,
  favorites: dto.favorites,
  views: dto.views,
  conversations: dto.conversations,
  promotedUntil: dto.promoted_until,
  promotionEndsInDays: dto.promotion_ends_in_days,
  humanizedHoursLeft: dto.humanized_hours_left,
  promotionEndedDaysAgo: dto.promotion_ended_days_ago,
  recentlyPromoted: dto.recently_promoted,
  impressionDifference: dto.impression_difference,
  chartData: dto.chart_data,
  nextPushUpTime: dto.next_push_up_time,
  lastPushUpAt: dto.last_push_up_at,
})
