/* eslint id-length: 0 */

import { Text } from '@vinted/web-ui'

type Props = {
  x?: number
  y?: number
  payload?: object & { value: string }
}

const Label = ({ x = 0, y = 0, payload = { value: '' } }: Props) => (
  <g transform={`translate(${x}, ${y})`}>
    <foreignObject x={-10} y={10} width={20} height={16}>
      <Text
        as="h4"
        text={payload.value}
        width={Text.Width.Parent}
        alignment={Text.Alignment.Center}
        type={Text.Type.Caption}
      />
    </foreignObject>
  </g>
)

export default Label
