'use client'

import { Children, ReactNode, useState } from 'react'
import { FormattedDate, useIntl } from 'react-intl'
import { Cell, Text, Divider } from '@vinted/web-ui'

import { FormattedMessage } from 'components/@support'
import PerformanceChart from 'components/PerformanceChart'

import { ItemPerformanceChartData } from 'types/dtos'
import { ItemPerformanceModel } from 'types/models'
import { PerformanceChartDataPoint as DataPoint } from 'types/components'

type Props = {
  itemPromoted: boolean
  performance: ItemPerformanceModel
  initialActivePoint?: number
}

const ItemPerformance = ({ performance, initialActivePoint, itemPromoted = false }: Props) => {
  const intl = useIntl()
  const [isDateSelected, setIsDateSelected] = useState(initialActivePoint !== undefined)
  const [activePoint, setActivePoint] = useState<number | undefined>(initialActivePoint)
  const [currentData, setCurrentData] = useState<ItemPerformanceChartData | undefined>(
    initialActivePoint === undefined ? undefined : performance.chartData[initialActivePoint],
  )

  function handleClick() {
    setIsDateSelected(false)
    setActivePoint(undefined)
    setCurrentData(undefined)
  }

  function handlePointClick(_payload: DataPoint | undefined, dataPointIndex: number | undefined) {
    if (dataPointIndex === undefined) return

    setIsDateSelected(true)
    setActivePoint(dataPointIndex)
    setCurrentData(performance.chartData[dataPointIndex])
  }

  function performanceDataToChartData(data: ItemPerformanceChartData) {
    const date = intl.formatDate(new Date(data.date), { day: '2-digit' })

    return {
      value_1: data.impressions,
      value_2: data.highlighted ? data.impressions : null,
      label: date,
    }
  }

  function renderInfo() {
    let text: ReactNode = null

    if (isDateSelected && currentData) {
      const { impressions } = currentData

      text = (
        <FormattedMessage
          id="item_performance.impressions"
          pluralize
          count={impressions}
          values={{
            count: <Text as="span" text={impressions} theme="amplified" />,
          }}
        />
      )
    } else {
      const { impressions } = performance

      const textParts: Array<JSX.Element | string> = [
        <FormattedMessage
          id="item_performance.impressions_over_days"
          pluralize
          count={impressions}
          values={{
            count: <Text as="span" text={impressions} theme="amplified" />,
            days: <Text as="span" text={performance.chartData.length} theme="amplified" />,
          }}
        />,
      ]

      if (performance.impressionDifference > 1) {
        textParts.push(
          <FormattedMessage
            id="item_performance.impression_diff"
            values={{
              change: (
                <Text as="span" text={`${performance.impressionDifference}x`} theme="amplified" />
              ),
            }}
          />,
        )
      }

      if (performance.recentlyPromoted) {
        if (itemPromoted) {
          textParts.push(<FormattedMessage id="item_performance.bump" />)
          textParts.push(`${performance.humanizedHoursLeft}.`)
        } else {
          textParts.push(
            <FormattedMessage
              id="item_performance.bump_ended_days_ago"
              pluralize
              count={performance.promotionEndedDaysAgo}
              values={{
                count: (
                  <Text as="span" text={performance.promotionEndedDaysAgo} theme="amplified" />
                ),
              }}
            />,
          )
        }
      }

      // Prevents "Each child in an array or iterator should have a unique 'key' prop" warning
      const parts = textParts.reduce(
        (acc: Array<JSX.Element | string>, part) => (acc.length ? [...acc, ' ', part] : [part]),
        [],
      )
      text = <Text as="span">{Children.toArray(parts)}</Text>
    }

    return text
  }

  function renderDate() {
    if (isDateSelected && currentData) {
      const date = new Date(currentData.date)

      return (
        <Text as="h3" type={Text.Type.Subtitle}>
          <FormattedDate value={date} year="numeric" month="long" day="numeric" />
        </Text>
      )
    }

    return (
      <Text as="h3" type={Text.Type.Subtitle}>
        <FormattedMessage
          id="item_performance.subtitle"
          values={{ days: performance.chartData.length }}
        />
      </Text>
    )
  }

  function renderInteractionsSubtitle() {
    return (
      <Text as="h3" type={Text.Type.Subtitle}>
        <FormattedMessage
          id="item_performance.interactions.subtitle"
          values={{
            days: performance.chartData.length,
          }}
        />
      </Text>
    )
  }

  function renderInteractions() {
    return (
      <Cell>
        <Cell
          title={<FormattedMessage id="item_performance.interactions.title" />}
          suffix={renderInteractionsSubtitle()}
          styling={Cell.Styling.Tight}
        />
        <Cell styling={Cell.Styling.Tight}>
          <Text as="span">
            <FormattedMessage
              id="item_performance.views"
              values={{
                count: <Text as="span" text={performance.views} theme="amplified" />,
              }}
            />
          </Text>
          <br />
          <Text as="span">
            <FormattedMessage
              id="item_performance.favourites"
              values={{
                count: <Text as="span" text={performance.favorites} theme="amplified" />,
              }}
            />
          </Text>
          <br />
          <Text as="span">
            <FormattedMessage
              id="item_performance.conversations"
              pluralize
              count={performance.conversations}
              values={{
                count: <Text as="span" text={performance.conversations} theme="amplified" />,
              }}
            />
          </Text>
        </Cell>
      </Cell>
    )
  }

  return (
    <Cell styling={Cell.Styling.Tight} onClick={handleClick} testId="bump-statistic">
      <Cell title={<FormattedMessage id="item_performance.title" />} suffix={renderDate()} />
      <div className="u-ui-padding-horizontal-medium">
        <PerformanceChart
          data={performance.chartData.map(performanceDataToChartData)}
          activePoint={activePoint}
          onPointClick={handlePointClick}
          interactive
        />
      </div>
      <Cell>{renderInfo()}</Cell>
      <Divider />
      {renderInteractions()}
    </Cell>
  )
}

export default ItemPerformance
