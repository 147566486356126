import { AttributeType, ItemPageAttributesDto } from 'types/dtos'

import { ItemPageAttributesBlockPluginModel } from './types'

const SUPPORTED_ATTRIBUTE_TYPES = Object.values(AttributeType)

export const getItemPageAttributesBlockPluginModel = (
  attributesPluginDto: ItemPageAttributesDto,
): ItemPageAttributesBlockPluginModel => ({
  attributes: attributesPluginDto.attributes.filter(attribute =>
    SUPPORTED_ATTRIBUTE_TYPES.includes(attribute.type),
  ),
  itemId: attributesPluginDto.item_id,
})
