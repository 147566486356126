import { ReactNode, useContext } from 'react'

import { ToggleableContext } from '../ToggleableContext'

type Props = {
  children: (props: { toggle: () => void; isVisible: boolean }) => ReactNode
}

const Toggle = ({ children }: Props) => {
  const { toggle, isVisible } = useContext(ToggleableContext)

  return children({ toggle, isVisible })
}

export default Toggle
