'use client'

import { useContext } from 'react'
import { Cell } from '@vinted/web-ui'

import { ToggleableContext } from '../ToggleableContext'

type CellProps = Omit<ComponentProps<typeof Cell>, 'onClick'>

type Props = CellProps & {
  id: string
  onClick?: (id: string, event: React.MouseEvent<Element>) => void
}

const MenuItem = ({ id, onClick, ...cellProps }: Props) => {
  const { close } = useContext(ToggleableContext)

  const handleClick = (event: React.MouseEvent<Element>) => {
    onClick?.(id, event)
    close()
  }

  return <Cell type={Cell.Type.Navigating} {...cellProps} onClick={handleClick} />
}

export default MenuItem
