'use client'

import { useMemo, useRef } from 'react'
import { InView } from 'react-intersection-observer'

import useFetch from 'hooks/useFetch'
import { getUserItems } from 'data/api'

import { ContentSource } from 'constants/tracking/content-sources'
import ItemViewItems from 'components/ItemViewItems'
import ContentLoader from 'components/ContentLoader'
import { SellerFiltersType } from 'pages/Profile/UserItems/SellerFilters/utils'
import { transformItemDtoToProductItem } from 'data/transformers'

import ViewAllUserItemsLink from './ViewAllUserItemsLink'
import { ItemPageClosetPluginDto } from './types'

type Props = {
  data: ItemPageClosetPluginDto
}

const ItemPageClosetPlugin = ({ data }: Props) => {
  const { itemId, itemUserId } = data

  const { fetch: fetchUserItems, data: userItemsData, isLoading } = useFetch(getUserItems)
  const hasFetched = useRef(false)

  const userItems = useMemo(() => {
    return userItemsData?.items.filter(item => item.id !== itemId) || []
  }, [userItemsData, itemId])

  const onInViewChange = (inView: boolean) => {
    if (!inView || hasFetched.current) return

    fetchUserItems({
      userId: itemUserId,
      currentPage: 0,
      perPage: 21,
      filter: SellerFiltersType.Active,
      selectedItemId: itemId,
    })
    hasFetched.current = true
  }

  return (
    <InView as="div" data-testid="item-page-closet-plugin" onChange={onInViewChange}>
      {isLoading && (
        <div className="u-ui-margin-top-medium">
          <ContentLoader testId="item-feed-loader" />
        </div>
      )}
      {userItems?.length > 0 && (
        <>
          <ItemViewItems
            items={userItems.map(transformItemDtoToProductItem)}
            contentSource={ContentSource.OtherUserItems}
            itemTestId="owner-item"
          />
          {!!userItemsData?.pagination.total_entries && (
            <ViewAllUserItemsLink
              ownerItemCount={userItemsData.pagination.total_entries}
              ownerId={itemUserId}
            />
          )}
        </>
      )}
    </InView>
  )
}

export default ItemPageClosetPlugin
