'use client'

import { Cell, Image, Text } from '@vinted/web-ui'
import classNames from 'classnames'

import { ItemShippingDetailsDiscountType } from 'constants/shipment'
import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'
import useIsDarkModeEnabledFromCookies from 'hooks/useIsDarkModeEnabledFromCookies'

type Props = {
  deliveryType: ItemShippingDetailsDiscountType
  discountPercentage: number
}

const ShippingDiscountBanner = ({ deliveryType, discountPercentage: percentage }: Props) => {
  const translate = useTranslate('item.shipment_options.discount.title')
  const asset = useAsset('/assets/shipping')
  const isDarkModeEnabled = useIsDarkModeEnabledFromCookies()

  const getTranslationKey = () => {
    if (deliveryType === ItemShippingDetailsDiscountType.All) return 'all'

    if (deliveryType === ItemShippingDetailsDiscountType.DropOff) return 'drop_off'

    return 'home'
  }

  const iconPath = asset('box-discount.svg', { theme: { dark: 'box-discount-dark.svg' } })

  const containerClassName = classNames('shipping-discount-banner', {
    'shipping-discount-banner--dark': isDarkModeEnabled,
  })

  return (
    <div className={containerClassName} data-testid="shipping-discount-banner">
      <Cell
        title={
          <Text
            as="h3"
            text={translate(getTranslationKey(), { percentage })}
            type={Text.Type.Subtitle}
            theme="amplified"
          />
        }
        suffix={
          <Image src={iconPath} size={Image.Size.Medium} testId="shipping-discount-banner-icon" />
        }
        theme="transparent"
      />
    </div>
  )
}

export default ShippingDiscountBanner
