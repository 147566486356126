'use client'

import { Button } from '@vinted/web-ui'

import { MARK_ITEM_AS_SOLD_URL } from 'constants/routes'
import useRefUrl from 'hooks/useRefUrl'
import useSession from 'hooks/useSession'
import useTranslate from 'hooks/useTranslate'
import { urlWithParams } from 'libs/utils/url'

import { ItemPageMarkAsSoldButtonPluginModel } from './types'

type Props = {
  data: ItemPageMarkAsSoldButtonPluginModel | undefined
}

const ItemPageMarkAsSoldButtonPlugin = ({ data }: Props) => {
  const translate = useTranslate()
  const refUrl = useRefUrl()
  const { user: currentUser } = useSession()

  if (!data) return null

  const { itemId, transactionPermitted, isClosed, userId } = data
  const isViewingOwnItem = userId === currentUser?.id

  if (!isViewingOwnItem) return null
  if (!(transactionPermitted && !isClosed)) return null

  const markAsSoldUrl = urlWithParams(MARK_ITEM_AS_SOLD_URL(itemId), {
    ref_url: refUrl,
  })

  return (
    <Button
      text={translate('item.actions.mark_as_sold')}
      url={markAsSoldUrl}
      size={Button.Size.Medium}
      testId="mark-as-sold-button"
    />
  )
}

export default ItemPageMarkAsSoldButtonPlugin
