'use client'

import { Spacer, Cell, Divider, Button } from '@vinted/web-ui'

import FollowButton from 'components/FollowButton'

import useTranslate from 'hooks/useTranslate'
import useSession from 'hooks/useSession'
import useLocation from 'hooks/useLocation'

import { NEW_MESSAGE_URL } from 'constants/routes/inbox'
import { isDateInTheFuture } from 'libs/utils/date'
import { urlWithParams } from 'libs/utils/url'

import { ItemPageUserInfoActionsPluginDto } from './types'

type Props = {
  data: ItemPageUserInfoActionsPluginDto
}

const ItemPageUserInfoActionsPlugin = ({ data }: Props) => {
  const translate = useTranslate()
  const { relativeUrl } = useLocation()
  const { user: currentUser } = useSession()

  const { user } = data

  if (!user) return null
  if (!currentUser || currentUser.id === user.id) return null
  if (isDateInTheFuture(user.closet_countdown_end_date)) return null

  if (user.hates_you || user.is_hated) {
    return (
      <Cell>
        <FollowButton userId={user.id} isActive={user.is_favourite} isFlat />
      </Cell>
    )
  }

  const messageUrl = urlWithParams(NEW_MESSAGE_URL, {
    receiver_id: user.id,
    ref_url: relativeUrl,
  })

  return (
    <Cell>
      <div className="u-flexbox">
        <Button
          text={translate('user.action.write_message')}
          styling={Button.Styling.Flat}
          url={messageUrl}
        />
        <Spacer size={Spacer.Size.Large} orientation={Spacer.Orientation.Vertical} />
        <Divider orientation={Divider.Orientation.Vertical} />
        <Spacer size={Spacer.Size.Large} orientation={Spacer.Orientation.Vertical} />
        <FollowButton userId={user.id} isActive={user.is_favourite} isFlat />
      </div>
    </Cell>
  )
}

export default ItemPageUserInfoActionsPlugin
