'use client'

import { MouseEvent } from 'react'
import { noop } from 'lodash'

type Props = {
  title: string
  url: string
  onClick?: (event: MouseEvent) => void
  testId?: string
}

const BreadcrumbsItem = ({ title, url, onClick = noop, testId }: Props) => (
  <li className="breadcrumbs__item">
    <a href={url} itemProp="url" onClick={onClick} data-testid={testId}>
      <span itemProp="title">{title}</span>
    </a>
  </li>
)

export default BreadcrumbsItem
