'use client'

import { Divider } from '@vinted/web-ui'

import { getItemStatus } from 'data/utils/item'
import useSession from 'hooks/useSession'

import ItemStatus from './ItemStatus'
import { ItemPageItemStatusPluginModel } from './types'

type Props = {
  data: ItemPageItemStatusPluginModel | undefined
}

const ItemPageItemStatusPlugin = ({ data }: Props) => {
  const currentUserId = useSession().user?.id

  const isViewingSelf = currentUserId === data?.userId

  if (!data || !getItemStatus(data, isViewingSelf)) return null

  return (
    <>
      <ItemStatus data={data} />
      <Divider />
    </>
  )
}

export default ItemPageItemStatusPlugin
