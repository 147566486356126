import { TransparencyLawSubject } from 'constants/index'
import TransparencyLaw from 'components/TransparencyLaw'

import { ItemPageTransparencyLawPluginModel } from './types'

type Props = {
  data?: ItemPageTransparencyLawPluginModel
}

const ItemPageTransparencyLawPlugin = ({ data }: Props) => {
  if (!data) return null
  if (data.isBusiness) return null

  return <TransparencyLaw subject={TransparencyLawSubject.BuyerRights} />
}

export default ItemPageTransparencyLawPlugin
