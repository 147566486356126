import { ItemDto, ItemPageDescriptionDto } from 'types/dtos'

import { ItemPageDescriptionPluginModel } from '../types'

export const getItemPageDescriptionPluginModelDeprecated = (itemDto: ItemDto) => ({
  itemId: itemDto.id,
  localization: itemDto.localization,
  title: itemDto.title,
  description: itemDto.description,
})

export const getItemPageDescriptionPluginModel = (
  descriptionPluginDto: ItemPageDescriptionDto,
): ItemPageDescriptionPluginModel => ({
  itemId: descriptionPluginDto.item_id,
  localization: descriptionPluginDto.localization,
  title: descriptionPluginDto.title,
  description: descriptionPluginDto.description,
})
