'use client'

import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'

import { MEMBER_PROFILE_URL } from 'constants/routes'
import { MAX_OWNER_ITEMS_PER_PAGE } from 'state/items/constants'
import { profileClickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'

type Props = {
  ownerItemCount: number
  ownerId: number
}

const ViewAllUserItemsLink = ({ ownerItemCount, ownerId }: Props) => {
  const translate = useTranslate('item')
  const { track } = useTracking()

  const handleOnClick = () => {
    track(
      profileClickEvent({
        target: ClickableElement.ViewAllUserItems,
        ownerId: ownerId.toString(),
      }),
    )
  }

  if (ownerItemCount < MAX_OWNER_ITEMS_PER_PAGE) return null

  return (
    <div className="u-ui-margin-vertical-x-large u-text-center">
      <a
        href={MEMBER_PROFILE_URL(ownerId)}
        onClick={handleOnClick}
        data-testid="view-all-user-items-link"
      >
        {translate('view_all_owner_items', { count: ownerItemCount })}
      </a>
    </div>
  )
}

export default ViewAllUserItemsLink
