import { ColorCode } from 'constants/ui'
import { VintedTheme } from 'types/ui'

export function colorCodeToHex(code: ColorCode): string | undefined {
  switch (code) {
    // primary
    case ColorCode.Primary:
      return '#007782'
    // secondary
    case ColorCode.Success:
      return '#28865a'
    case ColorCode.Expose:
      return '#f9bb42'
    case ColorCode.Warning:
      return '#d04555'
    // grayscale
    case ColorCode.GreyscaleLevel1:
      return '#171717'
    case ColorCode.GreyscaleLevel2:
      return '#4d4d4d'
    case ColorCode.GreyscaleLevel3:
      return '#757575'
    case ColorCode.GreyscaleLevel4:
      return '#999'
    case ColorCode.GreyscaleLevel5:
      return '#c9c9c9'
    case ColorCode.GreyscaleLevel6:
      return '#f2f2f2'
    case ColorCode.GreyscaleLevel7:
      return '#fff'
    // vendor
    case ColorCode.Facebook:
      return '#3b5998'
    case ColorCode.Google:
      return '#4285f4'
    case ColorCode.Apple:
      return '#000000'
    default:
      return undefined
  }
}

export function themeToColorCode(theme: VintedTheme): ColorCode | undefined {
  switch (theme) {
    case 'primary':
      return ColorCode.Primary
    case 'success':
      return ColorCode.Success
    case 'warning':
      return ColorCode.Warning
    case 'expose':
      return ColorCode.Expose
    case 'amplified':
      return ColorCode.GreyscaleLevel1
    case 'muted':
      return ColorCode.GreyscaleLevel3
    default:
      return undefined
  }
}

export const PRIMARY_COLOR_LIGHT = '#007782'
export const PRIMARY_COLOR_DARK = '#449da9'

export const getPrimaryColor = (darkMode?: boolean) =>
  darkMode ? PRIMARY_COLOR_DARK : PRIMARY_COLOR_LIGHT
